<template>
  <div class="container-fluid certificate" ref="document">
    <div class="row justify-content-center">
      <div class="col-xl-8 col-lg-8 col-md-10 col-12">
        <div class="certificate-border p-5">
          <div class="" id="element-to-convert">
            <div id="xx" class="text-center">
              <div class="logo">
                <img src="../../assets/img/logo.png" style="width: 100px" />
              </div>
            </div>

            <div class="mt-1">
              <div id="newTable0" class="">
                <h3 class="text-info mb-0 text-center">Reference feedback</h3>
              </div>
            </div>

            <div class="mt-4">
              <dl class="row">
                <dd class="col-sm-10 fw-700">Referee Name</dd>
                <dd class="col-sm-2 text-right fw-700">View</dd>
              </dl>

              <dl
                class="row"
                v-for="(item, index) in propsData2.reference_trans"
                :key="index"
              >
                <dd class="col-sm-10">
                  {{ item.referee_first_name }} {{ item.referee_last_name }}
                </dd>
                <dd class="col-sm-2 text-right">
                  <button
                    v-if="item.certificate_urls_chk"
                    class="btn btn-sm d-inline pr-1"
                    @click="fnGoDownloadCertificate(item.certificate_urls_chk)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Certificate"
                  >
                    <i class="fas fa-file-invoice text-info fs-1200"></i>
                  </button>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import * as miscellaneousService from "../../services/miscellaneous.service";
export default {
  name: "checkAcademicQualificationCertificate",

  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },

  methods: {
    /**
     * Some check require only download or open in separate page
     * @param {*} obj
     */
    async fnGoDownloadCertificate(url) {
      window.open(await this.fnGetEncryptedUrl(url), "_blank");
    },

    fnGetEncryptedUrl: async function (imgUrl) {
      let url = "";
      url = await miscellaneousService.fnGetTempUrl(imgUrl);
      return url;
    },
  },
};
</script>