<template>
  <div class="">
    <section v-if="gettStepId == 1">
      <appStep1 :mode="mode" :policeCheckId="policeCheckId"> </appStep1>
    </section>
    <section v-if="gettStepId == 2">
      <appStep2
        :mode="mode"
        :propsData="propsData"
        :policeCheckId="policeCheckId"
      ></appStep2>
    </section>
    <section v-if="gettStepId == 3">
      <appStep3
        :mode="mode"
        :propsData="propsData"
        :policeCheckId="policeCheckId"
      ></appStep3>
    </section>
    <section v-if="gettStepId == 4">
      <appStep4
        :mode="mode"
        :propsData="propsData"
        :policeCheckId="policeCheckId"
      >
      </appStep4>
    </section>
    <section v-if="gettStepId == 5">
      <appStep5
        :mode="mode"
        :propsData="propsData"
        :policeCheckId="policeCheckId"
      >
      </appStep5>
    </section>
    <section v-if="gettStepId == 6">
      <appStep6
        :mode="mode"
        :propsData="propsData"
        :policeCheckId="policeCheckId"
        :productId="productId"
        :propsNotesData="propsNotesData"
      >
      </appStep6>
    </section>
  </div>
</template>
<script>
import appStep1 from "../../components/policeCheck/step1.vue";
import appStep2 from "../../components/policeCheck/step2.vue";
import appStep3 from "../../components/policeCheck/step3.vue";
import appStep4 from "../../components/policeCheck/step4.vue";
import appStep5 from "../../components/policeCheck/step5.vue";
import appStep6 from "../../components/policeCheck/step6.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "policeCheckStepsEditByAdminCom",
  props: ["policeCheckId", "productId", "propsData", "propsNotesData"],
  components: {
    appStep1,
    appStep2,
    appStep3,
    appStep4,
    appStep5,
    appStep6,
  },
  computed: {
    ...mapGetters(["gettStepId", "getPoliceCheckSingle"]),
  },
  data() {
    return {
      mode: "edit",
    };
  },
  methods: {
    ...mapActions(["fetchStepId", "fetchPoliceCheckSingle"]),
  },
  created() {
    // this.fetchPoliceCheckSingle(this.policeCheckId);
  },
};
</script>
