<template>
  <div class="p-3">
    <appCheckEmploymentHistory
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckEmploymentHistory>
  </div>
</template>
<script>
import appCheckEmploymentHistory from "../../components/checkEmploymentHistory/checkEmploymentHistory.vue";
export default {
  name: "CheckEmploymentHistoryEdit",
  props: ["projectId", "productId", "userId"],

  components: {
    appCheckEmploymentHistory,
  },
};
</script>