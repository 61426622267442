<template>
  <div>
    <div class="sits-file-upload">
      <label class="">National Personal Insolvency Index</label>
      <div>
        <input
          type="file"
          id="actual-btn"
          ref="file"
          @change="uploadFile"
          hidden
        />
        <!-- our custom upload button -->
        <label class="upload-label" for="actual-btn"
          ><i class="fas fa-cloud-upload-alt text-info"></i> Please upload
          certificate</label
        >
        <!-- name of file chosen -->
        <span id="file-chosen">{{ documentName }}</span>
      </div>

      <div>
        <p class="mb-0">Insolvency Index Doc</p>
        <wdImageAvatarPreview
          :title="'Bankruptcy Certificate'"
          :coverImgUrl="userData.insolvency_index_url_chk"
          :fontAwClass="'fas fa-file-alt'"
          :isFileDelete="true"
          @delete-file="fnDocumentRemove(userData.id)"
        ></wdImageAvatarPreview>
      </div>
    </div>
    <dl class="row mb-0">
      <dd class="col-sm-12 text-right">
        <button class="btn btn-sm btn-info mt-3" @click="fnUpdateCheckList()">
          <b-spinner small v-if="loading" class="mr-2"></b-spinner>Save
        </button>
      </dd>
    </dl>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as chkBankruptcyRecordService from "../../services/chkBankruptcyRecord.service";
import wdImageAvatarPreview from "../wd/WDImageAvatarPreview.vue";

export default {
  name: "checkEmploymentHistoryCheckList",
  props: {
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    wdImageAvatarPreview,
  },
  data() {
    return {
      document: "",
      documentName: "",
      loading: false,
      userData: {
        previous_names_status: "",
        previous_names: [],
        insolvency_index_url_chk: "",
        status: "",
      },
    };
  },
  methods: {
    ...mapActions(["fetchBankruptcyRecord"]),

    /**
     * Update Bankruptcy Record
     */
    fnUpdateCheckList: async function () {
      const formData = new FormData();
      formData.append("file", this.document);
      formData.append("project_id", this.userData.project_id);

      formData.append(
        "previous_names_status",
        this.userData.previous_names_status
      );
      formData.append(
        "previous_names",
        JSON.stringify(this.userData.previous_names)
      );
      formData.append(
        "insolvency_index_url_chk",
        this.userData.insolvency_index_url_chk
      );
      formData.append("status", this.userData.status);

      try {
        this.loading = true;
        this.errors = {};

        await chkBankruptcyRecordService.addOrUpdate(
          this.userData.project_id,
          formData
        );

        this.fetchBankruptcyRecord(this.propsData2.project_id);

        this.documentName = "";
        this.loading = false;

        messageService.fnToastSuccess(
          "Bankruptcy Record - Successfully updated"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Bankruptcy Record",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Bankruptcy Record",
              "Something wrong"
            );
            break;
        }

        this.loading = false;
      }
    },

    /**
     * To remove Bankruptcy Record National Personal Insolvency Index
     */
    fnDocumentRemove: async function (id) {
      try {
        this.errors = {};

        await chkBankruptcyRecordService.removeFile(id);

        this.fetchBankruptcyRecord(this.propsData2.project_id);

        messageService.fnToastSuccess(
          "Bankruptcy Record, file removed - Successfully"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Bankruptcy Record",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Bankruptcy Record",
              "Something wrong"
            );
            break;
        }
      }
    },

    uploadFile() {
      this.document = this.$refs.file.files[0];
      this.documentName = this.$refs.file.files[0].name;
    },
  },
  watch: {
    propsData2(newVal) {
      Object.assign(this.userData, newVal);
    },
  },
};
</script>
