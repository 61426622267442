<template>
  <div>
    <div class="sits-file-upload">
      <label class="">Upload Right To WorkIn Australia Certificate</label>
      <div>
        <input
          type="file"
          id="actual-btn"
          ref="file"
          @change="uploadFile"
          hidden
        />
        <!-- our custom upload button -->
        <label class="upload-label" for="actual-btn"
          ><i class="fas fa-cloud-upload-alt text-info"></i> Please upload
          certificate</label
        >
        <!-- name of file chosen -->
        <span id="file-chosen">{{ documentName }}</span>
      </div>

      <div>
        <p v-if="userData.ext_certificate_url_chk" class="mb-0">
          Ext certificate
        </p>
        <wdImageAvatarPreview
          :title="'Ext certificate'"
          :coverImgUrl="userData.ext_certificate_url_chk"
          :fontAwClass="'fas fa-file-alt'"
          :isFileDelete="true"
          @delete-file="fnDocumentRemove(userData.id)"
        ></wdImageAvatarPreview>
      </div>
    </div>

    <dl class="row mb-0">
      <dd class="col-sm-12">
        <p class="mt-5 mb-0 fw-500">Document type</p>
        <p>{{ getDocumentType }}</p>
      </dd>
    </dl>

    <dl class="row mb-0" v-if="propsData2.document_type == 'passport'">
      <dd class="col-sm-12">
        <div class="mt-5">
          <label>Visa type</label>
          <input
            type="text"
            class="form-control"
            placeholder=" "
            v-model="userData.visa_type_chk"
          />
        </div>
      </dd>
    </dl>

    <dl class="row mb-0" v-if="propsData2.document_type == 'passport'">
      <dd class="col-sm-12">
        <div class="">
          <label>Grant date</label>
          <input
            type="date"
            class="form-control"
            placeholder=" "
            v-model="userData.grant_date_chk"
          />
        </div>
      </dd>
    </dl>

    <dl class="row mb-0" v-if="propsData2.document_type == 'passport'">
      <dd class="col-sm-12">
        <div class="">
          <label>Expiry date</label>
          <input
            type="date"
            class="form-control"
            placeholder=" "
            v-model="userData.expiry_date_chk"
          />
        </div>
      </dd>
    </dl>

    <dl class="row mb-0">
      <dd class="col-sm-12">
        <div class="">
          <label>Work entitlements</label>
          <input
            type="text"
            class="form-control"
            placeholder=" "
            v-model="userData.work_entitlements_chk"
          />
        </div>
      </dd>
    </dl>

    <dl class="row mb-0">
      <dd class="col-sm-12 text-right">
        <button class="btn btn-sm btn-info mt-3" @click="fnUpdateCheckList()">
          <b-spinner small v-if="loading" class="mr-2"></b-spinner>Save
        </button>
      </dd>
    </dl>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as chkRightToWorkInAustraliaService from "../../services/chkRightWorkAustralia.service";
import wdImageAvatarPreview from "../wd/WDImageAvatarPreview.vue";
import { optionsRightToWork } from "../../services/data.service";

export default {
  name: "RightToWorkInAustraliaCheckListCom",
  props: {
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    wdImageAvatarPreview,
  },
  data() {
    return {
      document: "",
      documentName: "",
      loading: false,
      userData: {
        id: "",
        project_id: "",
        document_type: "",
        passport_number: "",
        country_code: "",
        doc_urls: "",
        ext_certificate_url_chk: "",
        visa_type_chk: "",
        grant_date_chk: "",
        expiry_date_chk: "",
        work_entitlements_chk: "",
        status: "",
      },
    };
  },
  computed: {
    getDocumentType() {
      const getValue = optionsRightToWork.find((item) => {
        return item.value == this.propsData2.document_type;
      });
      return getValue?.text || "";
    },
  },
  methods: {
    ...mapActions(["fetchRightWorkAustralia"]),

    /**
     * Update Bankruptcy Record
     */
    fnUpdateCheckList: async function () {
      this.loading = true;
      this.errors = {};

      const formData = new FormData();
      formData.append("file2", this.document);
      formData.append("project_id", this.userData.project_id);
      formData.append("document_type", this.userData.document_type);
      formData.append("passport_number", this.userData.passport_number);
      formData.append("country_code", this.userData.country_code);
      formData.append("doc_urls", this.userData.doc_urls);
      formData.append(
        "ext_certificate_url_chk",
        this.userData.ext_certificate_url_chk
      );

      formData.append("visa_type_chk", this.userData.visa_type_chk);
      formData.append("grant_date_chk", this.userData.grant_date_chk);
      formData.append("expiry_date_chk", this.userData.expiry_date_chk);
      formData.append(
        "work_entitlements_chk",
        this.userData.work_entitlements_chk
      );

      formData.append("status", this.userData.status);

      try {
        const response = await chkRightToWorkInAustraliaService.addOrUpdate(
          this.userData.project_id,
          formData
        );

        if (response.data.status_code == 200) {
          this.fetchRightWorkAustralia(this.userData.project_id);
          messageService.fnToastSuccess("Saved successfully!");
          this.documentName = "";
        } else {
          this.loading = false;
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Right to work in Australia",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Right to work in Australia",
              "Something wrong"
            );
            break;
        }

        console.log("Something wrong - fnUpdateCheckList", error);

        this.loading = false;
      }
    },

    /**
     * To remove RightWork Australia certificate
     */
    fnDocumentRemove: async function (id) {
      try {
        this.errors = {};

        await chkRightToWorkInAustraliaService.removeFileExtCertificate(id);

        this.fetchRightWorkAustralia(this.propsData2.project_id);

        messageService.fnToastSuccess(
          "Right to work in Australia, file removed - Successfully"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Right to work in Australia",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Right to work in Australia",
              "Something wrong"
            );
            break;
        }

        console.log("Something wrong - fnDocumentRemove", error);
      }
    },

    uploadFile() {
      this.document = this.$refs.file.files[0];
      this.documentName = this.$refs.file.files[0].name;
    },
  },
  watch: {
    propsData2(newVal) {
      Object.assign(this.userData, newVal);
    },
  },
};
</script>
