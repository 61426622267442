<template>
  <div>
    <div class="sits-file-upload">
      <label class="">Driver licence traffic offense record</label>
      <div>
        <input
          type="file"
          id="actual-btn"
          ref="file"
          @change="uploadFile"
          hidden
        />
        <!-- our custom upload button -->
        <label class="upload-label" for="actual-btn"
          ><i class="fas fa-cloud-upload-alt text-info"></i> Please upload
          certificate</label
        >
        <!-- name of file chosen -->
        <span id="file-chosen">{{ documentName }}</span>
      </div>

      <div>
        <p class="mb-0" v-if="userData.ext_certificate_url_chk">
          Ext certificate
        </p>
        <wdImageAvatarPreview
          :title="'Photo of yourself'"
          :coverImgUrl="userData.ext_certificate_url_chk"
          :fontAwClass="'fas fa-file-alt'"
          :isFileDelete="true"
          @delete-file="fnDocumentRemove(userData.id)"
        ></wdImageAvatarPreview>
      </div>
    </div>
    <dl class="row mb-0">
      <dd class="col-sm-12 text-right">
        <button class="btn btn-sm btn-info mt-3" @click="fnUpdateCheckList()">
          <b-spinner small v-if="loading" class="mr-2"></b-spinner>Save
        </button>
      </dd>
    </dl>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as chkDriversLicenceOffencesService from "../../services/chkDriversOffences.service";
import wdImageAvatarPreview from "../wd/WDImageAvatarPreview.vue";

export default {
  name: "checkEmploymentHistoryCheckList",
  props: {
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    wdImageAvatarPreview,
  },
  data() {
    return {
      document: "",
      documentName: "",
      loading: false,
      userData: {
        id: "",
        project_id: "",
        licence_number: "",
        state_code: "",
        doc_urls: "",
        ext_certificate_url_chk: "",
        status: "",
      },
    };
  },
  methods: {
    ...mapActions(["fetchDriversOffences"]),

    /**
     * Update Bankruptcy Record
     */
    fnUpdateCheckList: async function () {
      this.loading = true;
      this.errors = {};

      const formData = new FormData();
      formData.append("file2", this.document);
      formData.append("project_id", this.userData.project_id);
      formData.append("licence_number", this.userData.licence_number);
      formData.append("state_code", this.userData.state_code);
      formData.append("doc_urls", this.userData.doc_urls);
      formData.append(
        "ext_certificate_url_chk",
        this.userData.ext_certificate_url_chk
      );
      formData.append("status", this.userData.status);

      try {
        this.loading = true;
        const response = await chkDriversLicenceOffencesService.addOrUpdate(
          this.userData.project_id,
          formData
        );

        if (response.data.status_code == 200) {
          this.fetchDriversOffences(this.userData.project_id);
          messageService.fnToastSuccess("Saved successfully!");
          this.documentName = "";
        } else {
          this.loading = false;
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Drivers Licence Traffic Offence",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Drivers Licence Traffic Offence",
              "Something wrong"
            );
            break;
        }

        console.log("Something wrong - fnCreate", error);

        this.loading = false;
      }
    },

    /**
     * To remove Drivers Licence Traffic Offence National Personal Insolvency Index
     */
    fnDocumentRemove: async function (id) {
      try {
        this.errors = {};

        await chkDriversLicenceOffencesService.removeFileExtCertificate(id);

        this.fetchDriversOffences(this.propsData2.project_id);

        messageService.fnToastSuccess(
          "Drivers Licence Traffic Offence, file removed - Successfully"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Drivers Licence Traffic Offence",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Drivers Licence Traffic Offence",
              "Something wrong"
            );
            break;
        }
      }
    },

    uploadFile() {
      this.document = this.$refs.file.files[0];
      this.documentName = this.$refs.file.files[0].name;
    },
  },
  watch: {
    propsData2(newVal) {
      Object.assign(this.userData, newVal);
    },
  },
};
</script>
