<template>
  <div class="container-fluid certificate" ref="document">
    <div class="row justify-content-center">
      <div class="col-xl-9 col-lg-9 col-md-12 col-12">
        <div class="" id="element-to-convert">
          <div id="xx" class="text-center">
            <div class="logo pt-3 pb-3">
              <img src="../../assets/img/logo.png" style="width: 100px" />
            </div>
          </div>

          <div>
            <wdImage
              v-if="urlCertification"
              :imgUrl="urlCertification"
              :imgStyle="'height: 700px; width: 100%'"
              :imgClass="'card-img-top'"
            ></wdImage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wdImage from "../../components/wd/WDImage.vue";
export default {
  name: "policeCheckCertificateCom",
  props: ["urlCertification"],
  components: {
    wdImage,
  },
};
</script>