<template>
  <div class="pt-3">
    <appCheckDriversLicenceTrafficOffence
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckDriversLicenceTrafficOffence>
  </div>
</template>
<script>
import appCheckDriversLicenceTrafficOffence from "../../components/checkDriversLicenceTrafficOffence/checkDriversLicenceTrafficOffence.vue";
export default {
  name: "CheckDriversLicenceTrafficOffenceEdit",
  props: ["projectId", "productId", "userId"],

  components: {
    appCheckDriversLicenceTrafficOffence,
  },
  data() {
    return {};
  },
};
</script>