<template>
  <div class="pt-3">
    <appRightToWorkInAustralia
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appRightToWorkInAustralia>
  </div>
</template>
<script>
import appRightToWorkInAustralia from "../../components/checkRightToWorkInAustralia/RightToWorkInAustralia.vue";
export default {
  name: "RightToWorkInAustraliaEdit",
  props: ["projectId", "productId", "userId"],

  components: {
    appRightToWorkInAustralia,
  },
};
</script>