<template>
  <div>
    <a
      href="javascript: void(0)"
      data-toggle="tooltip"
      data-placement="top"
      title="Edit Employment History"
      @click="fnShowModal()"
      ><i class="far fa-edit text-info"></i
    ></a>
    <b-modal
      ref="employmentHistoryModal"
      size="xl"
      hide-footer
      title="Employment History"
    >
      <div class="d-block">
        <div class="alert alert-danger mb-5 col-12" v-if="arrErr.length">
          <p
            class="fs-800 mb-0"
            v-for="(item, index) in arrErr"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
        <form v-on:submit.prevent="fnUpdate">
            <div class="row">
              <div class="col-lg-8 col-md-8 col-12">
                  <div class="form-group">
                      <input
                      id="name_of_organisation"
                      type="text"
                      class="form-control"
                      placeholder=" "
                      v-model="userData.name_of_organisation"
                      rows="7"
                      />
                      <label class="floatingLabel" for="lastName"
                      >Name of organisation candidate employed by</span
                      ></label
                      >
                  </div>
              </div>
                
              <div class="col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                      <input
                        id="payroll_phone_number"
                        type="text"
                        class="form-control"
                        placeholder=" "
                        v-model="userData.payroll_phone_number"
                      />
                      <label class="floatingLabel" for="payroll_phone_number">Employer Payroll Phone Number</label>
                  </div>
              </div>
              
              <div class="col-lg-8 col-md-8 col-12">
                  <div class="form-group">
                      <input
                        id="employer_address"
                        class="form-control"
                        type="text"
                        autocomplete="off_employer_address"
                        placeholder=" "
                        v-model="userData.employer_address"
                      />
                      <label class="floatingLabel">Employer Address</label>
                  </div>
              </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                      <select
                      id="country_code"
                      class="form-control"
                      v-model="userData.country_code"
                      >
                      <template v-for="(country, i) in getSettingAll.country">
                          <option
                          :value="country.alpha_3"
                          :key="i"
                          >
                          {{ country.name }}
                          </option>
                      </template>
                      </select>
                      <label class="floatingLabel vueBootSelect" for="doc1_country"
                      >Employer country</label
                      >
                  </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                      <input
                        id="employer_payroll_email"
                        class="form-control"
                        type="text"
                        autocomplete="off_employer_payroll_email"
                        placeholder=" "
                        v-model="userData.employer_payroll_email"
                      />
                      <label class="floatingLabel">Employer HR/Payroll Email</label>
                  </div>
              </div>
              
              <div class="col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                      <input
                        id="role_title"
                        class="form-control"
                        type="text"
                        autocomplete="off_role_title"
                        placeholder=" "
                        v-model="userData.role_title"
                      />
                      <label class="floatingLabel">Role Title</label>
                  </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                <div class="form-group">
                  <select class="form-control" v-model="userData.nature_of_employment">
                    <option
                      v-for="item in natureOfEmploymentOptions"
                      :key="item.name"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label class="floatingLabel">Nature of Employment</label>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                  <input
                    id="manager_name"
                    class="form-control"
                    type="text"
                    autocomplete="off_manager_name"
                    placeholder=" "
                    v-model="userData.manager_name"
                  />
                  <label class="floatingLabel">Manager Name</label>
                  </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                  <div class="form-group ">
                      <label class="">Is this your current employer?</label>
                      <div class="form-check">
                          <input class="form-check-input" type="radio" name="gridRadios" v-model="userData.is_current_employer" value="Yes">
                          <label class="form-check-label" for="gridRadios1">
                          Yes
                          </label>
                      </div>
                      <div class="form-check">
                          <input class="form-check-input" type="radio" name="gridRadios"  v-model="userData.is_current_employer" value="No">
                          <label class="form-check-label" for="gridRadios2">
                          No
                          </label>
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                  <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                          <div class="form-group">
                              <input
                              id="date_from_position_held"
                              class="form-control"
                              type="date"
                              autocomplete="off_date_from_position_held"
                              placeholder="Position Held From"
                              v-model="userData.date_from_position_held"
                              />
                              <label class="floatingLabel">Position Held From</label>
                          </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                          <div class="form-group" v-if="userData.is_current_employer == 'No'">
                              <input
                              id="date_to_position_held"
                              class="form-control"
                              type="date"
                              autocomplete="off_date_from_position_held"
                              placeholder="Position Held To"
                              v-model="userData.date_to_position_held"
                              />
                              <label class="floatingLabel">Position Held To</label>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                  <div class="form-group" v-if="this.userData.is_current_employer == 'No'">
                      <textarea
                        id="reason_for_leaving"
                        type="text"
                        class="form-control"
                        placeholder=" "
                        v-model="userData.reason_for_leaving"
                        rows="7"
                      />
                      <label class="floatingLabel" for="lastName"
                          >Please provide a reason for leaving</span
                          ></label
                      >
                  </div>
              </div>

              <div class="col-lg-4 col-md-4 col-12">
                  <div class="form-group ">
                      <label class="">Can we contact this employer?</label>
                      <div class="form-check">
                          <input class="form-check-input" type="radio" name="canContactEmployer" v-model="userData.can_contact_employer" value="Yes">
                          <label class="form-check-label" for="can_contact_employer1">
                          Yes
                          </label>
                      </div>
                      <div class="form-check">
                          <input class="form-check-input" type="radio" name="canContactEmployer" v-model="userData.can_contact_employer"  value="No">
                          <label class="form-check-label" for="can_contact_employer2">
                          No
                          </label>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                <div class="sits-file-upload" v-if="userData.can_contact_employer=='No'">
                      <label class="mb-0">Proof of employment</label>
                      <label><span class="text-danger fs-800">(Please upload a payslip, contract or letter of offer.)</span></label>
                      <div>
                          <div class="upload-view">
                            <div class="mr500"> 
                              <input type="file" id="actual-btn" ref="file" @change="uploadFile" hidden/>
                              <!-- our custom upload button -->
                              <label  class="upload-label" for="actual-btn"><i class="fas fa-cloud-upload-alt text-info"></i> Upload document/s</label>
                            </div>
                            <wdImageAvatarPreview
                              :title="'Proof of employment'"
                              :coverImgUrl="userData.doc_urls"
                              :fontAwClass="'fas fa-file-alt'"
                              :fontAwSize="'fs-2300'"
                              :isFileDelete="true"
                              @delete-file="fnDocumentRemove(userData.id)"
                            ></wdImageAvatarPreview>
                          </div>
                          <!-- name of file chosen -->
                          <span id="file-chosen">{{ documentName }}</span>
                      </div>
                </div>
              </div>
            </div>


          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm"><b-spinner small v-if="loading" class="mr-2"></b-spinner>Save</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as messageService from "../../services/message.service";
import * as chkEmploymentHistoryService from "../../services/chkEmploymentHistory.service";
import { mapGetters, mapActions } from "vuex";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";

export default {
  name: "ModalEmploymentHistoryEdit",
  props: ["projectId", "productId", "propsData"],
  components: {
    wdImageAvatarPreview,
  },
  data() {
    return {
      errors: {},
      arrErr: [],
      document: "",
      documentName: "",
      userData: {
        id: "",
        name_of_organisation: "",
        payroll_phone_number: "",
        employer_address: "",
        country_code: "",
        employer_payroll_email: "",
        role_title: "",
        nature_of_employment: "",
        manager_name: "",
        is_current_employer: "",
        date_from_position_held: "",
        date_to_position_held: "",
        reason_for_leaving: "",
        can_contact_employer: "",
        doc_urls: "",

        name_of_organisation_chk: 0,
        payroll_phone_number_chk: 0,
        employer_address_chk: 0,
        country_code_chk: 0,
        employer_payroll_email_chk: 0,
        role_title_chk: 0,
        nature_of_employment_chk: 0,
        manager_name_chk: 0,
        is_current_employer_chk: 0,
        date_from_position_held_chk: 0,
        date_to_position_held_chk: 0,
        reason_for_leaving_chk: 0,
        can_contact_employer_chk: 0,
        notes_chk: "",
        status: 1,
      },
      natureOfEmploymentOptions: [
        { name: "Full time" },
        { name: "Part time" },
        { name: "Casual" },
        { name: "Other" },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getEmploymentHistory"]),
  },

  methods: {
    ...mapActions(["fetchEmploymentHistory"]),
    fnShowModal() {
      this.showModal();
    },
    hideModal() {
      this.$refs.employmentHistoryModal.hide();
    },
    showModal() {
      Object.assign(this.userData, this.propsData);

      this.$refs.employmentHistoryModal.show();
    },
    uploadFile() {
      this.document = this.$refs.file.files[0];
      this.documentName = this.$refs.file.files[0].name;
    },
    fnValidation() {
      this.arrErr = [];
      if (
        this.userData.name_of_organisation == "" ||
        this.userData.name_of_organisation == null
      ) {
        this.arrErr.push(
          "Name of organisation candidate employed by is required"
        );
      }

      if (
        this.userData.payroll_phone_number == "" ||
        this.userData.payroll_phone_number == null
      ) {
        this.arrErr.push("Employer Payroll Phone Number is required");
      }

      if (
        this.userData.employer_address == "" ||
        this.userData.employer_address == null
      ) {
        this.arrErr.push("Employer Address is required");
      }

      if (
        this.userData.country_code == "" ||
        this.userData.country_code == null
      ) {
        this.arrErr.push("Employer country is required");
      }

      if (
        this.userData.employer_payroll_email == "" ||
        this.userData.employer_payroll_email == null
      ) {
        this.arrErr.push("Employer HR/Payroll EmaiL is required");
      }

      if (this.userData.role_title == "" || this.userData.role_title == null) {
        this.arrErr.push("Role Title is required");
      }

      if (
        this.userData.nature_of_employment == "" ||
        this.userData.nature_of_employment == null
      ) {
        this.arrErr.push("Nature of employment is required");
      }

      if (
        this.userData.manager_name == "" ||
        this.userData.manager_name == null
      ) {
        this.arrErr.push("Manager Name is required");
      }
      if (
        this.userData.is_current_employer == "" ||
        this.userData.is_current_employer == null
      ) {
        this.arrErr.push("Is this your current employer is required");
      }

      if (
        this.userData.date_from_position_held == "" ||
        this.userData.date_from_position_held == null
      ) {
        this.arrErr.push("Position Held From is required");
      }

      if (this.userData.is_current_employer == "No") {
        if (
          this.userData.date_to_position_held == "" ||
          this.userData.date_to_position_held == null
        ) {
          this.arrErr.push("Position Held From date is required");
        }

        if (
          this.userData.reason_for_leaving == "" ||
          this.userData.reason_for_leaving == null
        ) {
          this.arrErr.push("Please provide a reason for leaving");
        }
      }

      if (
        this.userData.can_contact_employer == "No" &&
        this.userData.doc_urls == ""
      ) {
        if (this.documentName == "") {
          this.arrErr.push(
            "Please upload either a payslip, contract or letter of offer as proof of employment"
          );
        }
      }

      return this.arrErr;
    },

    fnUpdate: async function () {
      this.loading = true;
      this.errors = {};
      this.fnValidation();

      if (this.arrErr.length > 0) {
        return;
      }

      const formData = new FormData();
      formData.append("file", this.document);
      formData.append("project_id", this.projectId);

      formData.append(
        "name_of_organisation",
        this.userData.name_of_organisation
      );
      formData.append(
        "payroll_phone_number",
        this.userData.payroll_phone_number
      );
      formData.append("employer_address", this.userData.employer_address);
      formData.append("country_code", this.userData.country_code);
      formData.append(
        "employer_payroll_email",
        this.userData.employer_payroll_email
      );

      formData.append("role_title", this.userData.role_title);
      formData.append(
        "nature_of_employment",
        this.userData.nature_of_employment
      );
      formData.append("manager_name", this.userData.manager_name);
      formData.append("is_current_employer", this.userData.is_current_employer);
      formData.append(
        "date_from_position_held",
        this.userData.date_from_position_held
      );

      formData.append(
        "date_to_position_held",
        this.userData.date_to_position_held
      );
      formData.append("reason_for_leaving", this.userData.reason_for_leaving);
      formData.append(
        "can_contact_employer",
        this.userData.can_contact_employer
      );

      formData.append(
        "name_of_organisation_chk",
        this.userData.name_of_organisation_chk
      );
      formData.append(
        "payroll_phone_number_chk",
        this.userData.payroll_phone_number_chk
      );
      formData.append(
        "employer_address_chk",
        this.userData.employer_address_chk
      );
      formData.append("country_code_chk", this.userData.country_code_chk);
      formData.append(
        "employer_payroll_email_chk",
        this.userData.employer_payroll_email_chk
      );
      formData.append("role_title_chk", this.userData.role_title_chk);
      formData.append(
        "nature_of_employment_chk",
        this.userData.nature_of_employment_chk
      );
      formData.append("manager_name_chk", this.userData.manager_name_chk);
      formData.append(
        "is_current_employer_chk",
        this.userData.is_current_employer_chk
      );
      formData.append(
        "date_from_position_held_chk",
        this.userData.date_from_position_held_chk
      );

      formData.append(
        "date_to_position_held_chk",
        this.userData.date_to_position_held_chk
      );
      formData.append(
        "reason_for_leaving_chk",
        this.userData.reason_for_leaving_chk
      );
      formData.append(
        "can_contact_employer_chk",
        this.userData.can_contact_employer_chk
      );
      formData.append("notes_chk", this.userData.notes_chk);

      try {
        this.errors = {};

        await chkEmploymentHistoryService.updateSingle(
          this.userData.id,
          formData
        );
        this.document = "";
        this.documentName = "";
        this.fetchEmploymentHistory(this.projectId);
        this.loading = false;

        this.hideModal();

        messageService.fnToastSuccess(
          "Employment History - Successfully updated"
        );

        this.userData = {
          id: "",
          name_of_organisation: "",
          payroll_phone_number: "",
          employer_address: "",
          country_code: "",
          employer_payroll_email: "",
          role_title: "",
          nature_of_employment: "",
          manager_name: "",
          is_current_employer: "",
          date_from_position_held: "",
          date_to_position_held: "",
          reason_for_leaving: "",
          can_contact_employer: "",
        };
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Employment History",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Employment History",
              "Something wrong"
            );
            break;
        }
        this.loading = false;
      }
    },
    fnDocumentRemove: async function (id) {
      try {
        this.errors = {};

        await chkEmploymentHistoryService.removeFile(id);

        await this.fetchEmploymentHistory(this.projectId);

        this.userData = {
          id: "",
          name_of_organisation: "",
          payroll_phone_number: "",
          employer_address: "",
          country_code: "",
          employer_payroll_email: "",
          role_title: "",
          nature_of_employment: "",
          manager_name: "",
          is_current_employer: "",
          date_from_position_held: "",
          date_to_position_held: "",
          reason_for_leaving: "",
          can_contact_employer: "",
          doc_urls: "",
        };

        this.document = "";
        this.documentName = "";

        this.hideModal();

        messageService.fnToastSuccess(
          "Employment History, file removed - Successfully"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Employment History",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Employment History",
              "Something wrong"
            );
            break;
        }
      }
    },
  },
};
</script>