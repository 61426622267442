<template>
  <div class="pt-3">
    <appCheckReference
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckReference>
  </div>
</template>
<script>
import appCheckReference from "../../components/checkReference/checkReference.vue";
export default {
  name: "CheckReferenceEdit",
  props: ["projectId", "productId", "userId"],

  components: {
    appCheckReference,
  },
};
</script>