<template>
  <div class="pt-3">
    <appCheckAcademicQualification
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckAcademicQualification>
  </div>
</template>
<script>
import appCheckAcademicQualification from "../../components/checkAcademicQualification/checkAcademicQualification.vue";
export default {
  name: "CheckAcademicQualificationEdit",
  props: ["projectId", "productId", "userId"],

  components: {
    appCheckAcademicQualification,
  },
};
</script>