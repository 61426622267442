<template>
  <div class="pt-3">
    <appCheckProfessionalMembership
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckProfessionalMembership>
  </div>
</template>
<script>
import appCheckProfessionalMembership from "../../components/checkProfessionalMembership/checkProfessionalMembership.vue";
export default {
  name: "CheckProfessionalMembershipEdit",
  props: ["projectId", "productId", "userId"],

  components: {
    appCheckProfessionalMembership,
  },
};
</script>