import { render, staticRenderFns } from "./CheckProfessionalMembershipEdit.vue?vue&type=template&id=db05f142&"
import script from "./CheckProfessionalMembershipEdit.vue?vue&type=script&lang=js&"
export * from "./CheckProfessionalMembershipEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports