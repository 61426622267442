<template>
  <div class="container-fluid certificate" ref="document">
    <div class="row justify-content-center">
      <div class="col-xl-8 col-lg-8 col-md-12 col-12 text-right pb-3">
        <button class="btn btn-info btn-sm mr-2" @click="exportToPDF()">
          Print Certificate
        </button>
      </div>

      <div class="col-xl-8 col-lg-8 col-md-10 col-12">
        <div class="certificate-border p-5">
          <div class="" id="element-to-convert">
            <div id="xx" class="text-center">
              <div class="logo">
                <img src="../../assets/img/logo.png" style="width: 100px" />
              </div>
            </div>

            <div class="mt-1">
              <div id="newTable0" class="">
                <h3 class="text-info mb-0 text-center">
                  Academic Qualification Certificate
                </h3>
              </div>
            </div>

            <div class="mt-4" id="page_brake_1">
              <dl class="row">
                <dd class="col-sm-12 text-black-60 fw-600">
                  <h5 class="text-info mb-0">Candidate's Details</h5>
                </dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">
                  Family/Surname membership held in
                </dd>
                <dd class="col-sm-7 mb-0">{{ propsData1.last_name }}</dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">
                  Given Name(s) membership membership held in
                </dd>
                <dd class="col-sm-7 mb-0">{{ propsData1.first_name }}</dd>
              </dl>

              <dl class="row">
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">
                  Date of birth
                </dd>
                <dd class="col-sm-7 mb-0">{{ propsData1.date_of_birth }}</dd>
              </dl>
            </div>

            <div class="mt-4">
              <dl class="row">
                <dd class="col-sm-12 text-black-60 fw-600">
                  <h5 class="text-info mb-0">Qualification Details</h5>
                </dd>
                <dd class="col-sm-12 text-black-60 fw-600">
                  <p class="text-black-60 fw-600 mb-0">
                    INFORMATION PROVIDED BY THE CANDIDATE
                  </p>
                </dd>
                <dd class="col-sm-12 text-black-60 fw-400">
                  <p>
                    The following information was provided by the candidate.
                    Verification of this information was achieved through direct
                    confirmation from the issuing body.
                  </p>
                </dd>
              </dl>
            </div>
            <div class="mt-4">
              <dl
                class="row"
                v-for="(item, index) in propsData2.academic_qualification_trans"
                :key="index"
              >
                <dd class="col-sm-12 mb-0">
                  <p class="text-muted fs-1200 fw-600 mb-0">
                    Name of organisation/institution that issued the
                    qualification
                  </p>
                </dd>
                <dd class="col-sm-10">
                  {{ item.name_of_organisation }}
                </dd>
                <dd class="col-sm-2 text-right">
                  <span
                    v-if="item.name_of_organisation_chk == '1'"
                    class="text-success"
                    ><i class="far fa-check-circle"></i
                  ></span>
                  <span
                    v-if="item.name_of_organisation_chk == '0'"
                    class="text-danger"
                    ><i class="far fa-times-circle"></i
                  ></span>
                </dd>

                <dd class="col-sm-12 mb-0">
                  <p class="text-muted fs-1200 fw-600 mb-0">
                    Qualification obtained
                  </p>
                </dd>
                <dd class="col-sm-10">{{ item.qualification_obtained }}</dd>
                <dd class="col-sm-2 text-right">
                  <span
                    v-if="item.qualification_obtained_chk == '1'"
                    class="text-success"
                    ><i class="far fa-check-circle"></i
                  ></span>
                  <span
                    v-if="item.qualification_obtained_chk == '0'"
                    class="text-danger"
                    ><i class="far fa-times-circle"></i
                  ></span>
                </dd>
                <dd class="col-sm-12 mb-0">
                  <p class="text-muted fs-1200 fw-600 mb-0">
                    Date qualification obtained
                  </p>
                </dd>
                <dd class="col-sm-10">
                  {{ item.date_qualification_obtained }}
                </dd>
                <dd class="col-sm-2 text-right">
                  <span
                    v-if="item.date_qualification_obtained_chk == 1"
                    class="text-success"
                    ><i class="far fa-check-circle"></i
                  ></span>
                  <span
                    v-if="item.date_qualification_obtained_chk == 0"
                    class="text-danger"
                    ><i class="far fa-times-circle"></i
                  ></span>
                </dd>

                <dd class="col-sm-12 text-black-60 fw-600 mt-4">
                  <h5 class="text-info mb-0">Provider Details</h5>
                </dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">Name</dd>
                <dd class="col-sm-7 mb-0">{{ item.provider_name_chk }}</dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">
                  Position/Department
                </dd>
                <dd class="col-sm-7 mb-0">
                  {{ item.provider_position_chk }}
                </dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0 mt-3">
                  Organisation
                </dd>
                <dd class="col-sm-7 mb-0">
                  {{ item.provider_organisation_chk }}
                </dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">Date</dd>
                <dd class="col-sm-7 mb-0">
                  {{ item.provider_given_date_chk }}
                </dd>

                <dd class="col-sm-12">
                  <hr />
                </dd>

                <div class="html2pdf__page-break"></div>
              </dl>
            </div>

            <div class="mt-5">
              <dl class="row">
                <dd class="col-sm-12">
                  <p class="fs-800">
                    Please Note: This report contains information of the
                    individual named above that is governed by national privacy
                    legislation and cannot be used or disclosed beyond the
                    purpose for which that individual has given his or her
                    consent.
                  </p>
                  <p class="fs-800">
                    Unless this report has been downloaded directly from CV
                    Screen’s online platform (via secure login), it is not CV
                    Screen’s original report and may have been altered.
                  </p>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "checkAcademicQualificationCertificate",

  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },

  methods: {
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "academic-qualification-certificate.pdf",
        jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
      });
    },
  },
};
</script>