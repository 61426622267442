<template>
  <div class="container-fluid certificate" ref="document">
    <div class="row justify-content-center">
      <div class="col-xl-8 col-lg-8 col-md-12 col-12 text-right pb-3">
        <button class="btn btn-info btn-sm mr-2" @click="exportToPDF()">
          Print Certificate
        </button>
      </div>

      <div class="col-xl-8 col-lg-8 col-md-10 col-12">
        <div class="certificate-border p-5">
          <div class="" id="element-to-convert">
            <div id="xx" class="text-center">
              <div class="logo">
                <img src="../../assets/img/logo.png" style="width: 100px" />
              </div>
            </div>

            <div class="mt-1">
              <div id="newTable0" class="">
                <h3 class="text-info mb-0 text-center">
                  Bankruptcy Record Certificate
                </h3>
              </div>
            </div>

            <div class="mt-4">
              <dl class="row">
                <dd class="col-sm-12 text-black-60 fw-600">
                  <h5 class="text-info mb-0">Candidate's Details</h5>
                </dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">
                  Family/Surname
                </dd>
                <dd class="col-sm-7 mb-0">{{ propsData1.last_name }}</dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">
                  Given Name(s)
                </dd>
                <dd class="col-sm-7 mb-0">{{ propsData1.first_name }}</dd>
                <dd class="col-sm-5 text-black-60 fw-600 mb-0">
                  Date of birth
                </dd>
                <dd class="col-sm-7 mb-0">{{ propsData1.date_of_birth }}</dd>
              </dl>
            </div>

            <div class="mt-4">
              <dl class="row">
                <dd class="col-sm-12 text-black-60 fw-600">
                  <h5 class="text-info mb-0">Result</h5>
                </dd>
                <dd class="col-sm-12 text-black-60 fw-400">
                  <p>
                    The result for
                    <span class="fw-600"
                      >{{ propsData1.first_name }}
                      {{ propsData1.last_name }}</span
                    >
                    is attached
                  </p>
                </dd>
              </dl>
            </div>
            <div class="mt-5 mb-5">
              <dl class="row">
                <dd class="col-sm-12">
                  <p class="fs-800">
                    Please Note: This report contains information of the
                    individual named above that is governed by national privacy
                    legislation and cannot be used or disclosed beyond the
                    purpose for which that individual has given his or her
                    consent.
                  </p>
                  <p class="fs-800">
                    Unless this report has been downloaded directly from CV
                    Screen’s online platform (via secure login), it is not CV
                    Screen’s original report and may have been altered.
                  </p>
                </dd>
              </dl>
            </div>

            <div class="html2pdf__page-break"></div>

            <div class="">
              <wdImage
                v-if="propsData2.insolvency_index_url_chk"
                :imgUrl="propsData2.insolvency_index_url_chk"
                :imgStyle="'width: 99.9%'"
              ></wdImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wdImage from "../../components/wd/WDImage.vue";
import html2pdf from "html2pdf.js";
export default {
  name: "checkBankruptcyCertificate",

  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    wdImage,
  },
  methods: {
    exportToPDF() {
      const element = document.getElementById("element-to-convert");
      const opt = {
        margin: 1,
        filename: "check-bankruptcy-certificate.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          logging: true,
          dpi: 192,
          letterRendering: true,
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      // New Promise-based usage:
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>