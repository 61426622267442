<template>
  <div class="p-4">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12 text-left">
            <h4>Projects</h4>
          </div>
        </div>
      </div>

      <div class="card-body">
        <!-- Desktop view -->
        <div class="desktop-view">
          <div class="search-row">
            <div class="select-search" v-if="loginUserObj">
              <div class="search-box">
                <div class="input-group form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-describedby="basic-addon2"
                    v-model.lazy="search"
                  />

                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      id="basic-addon2"
                      @click="fnSearch()"
                      >Search....</span
                    >
                  </div>
                  <label for="paginate" class="floatingLabel">Search ...</label>
                </div>
              </div>
            </div>

            <div class="export-box">
              <div class="date-box">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="date"
                    id="fromDate"
                    autocomplete="off_fromDate"
                    placeholder=" "
                    v-model="fromDate"
                  />
                  <label class="floatingLabel" for="fromDate">From date</label>
                </div>
              </div>
              <div class="date-box">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="date"
                    id="toDate"
                    autocomplete="off_toDate"
                    placeholder=" "
                    v-model="toDate"
                  />
                  <label class="floatingLabel" for="toDate">To date</label>
                </div>
              </div>
              <div>
                <button class="btn btn-sm btn-info" @click="fnSearch()">
                  Filter
                </button>
              </div>
              <div>
                <button
                  class="btn btn-sm btn-info"
                  @click="fnExport()"
                  :disabled="isExport"
                >
                  Export
                </button>
              </div>
              <div>
                <button class="btn btn-sm btn-info" @click="fnClear()">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile view -->
        <div class="mobile-view">
          <div class="search-row">
            <div class="select-search" v-if="loginUserObj">
              <div class="row">
                <div class="col-12">
                  <div class="input-group form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-describedby="basic-addon2"
                      v-model.lazy="search"
                    />

                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        id="basic-addon2"
                        @click="fnSearch()"
                        >Search....</span
                      >
                    </div>
                    <label for="paginate" class="floatingLabel"
                      >Search ...</label
                    >
                  </div>
                </div>

                <div class="col-12">
                  <div class="filter-box">
                    <div class="date-box">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="date"
                          id="fromDate"
                          autocomplete="off_fromDate"
                          placeholder=" "
                          v-model="fromDate"
                        />
                        <label class="floatingLabel" for="fromDate"
                          >From date</label
                        >
                      </div>
                    </div>
                    <div class="date-box">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="date"
                          id="toDate"
                          autocomplete="off_toDate"
                          placeholder=" "
                          v-model="toDate"
                        />
                        <label class="floatingLabel" for="toDate"
                          >To date</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="filter-box-right">
                    <div>
                      <button class="btn btn-sm btn-info" @click="fnSearch()">
                        Filter
                      </button>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-info"
                        @click="fnExport()"
                        :disabled="isExport"
                      >
                        Export
                      </button>
                    </div>
                    <div>
                      <button class="btn btn-sm btn-info" @click="fnClear()">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive table-sm policeCheckList">
          <table
            class="v-datatable-light table table-bordered table-hover table-striped table-center"
          >
            <thead>
              <tr>
                <th></th>
                <th class="header-item header-column-1">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('id')"
                    >Id</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'id'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'id'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-2">Stake holder</th>
                <th class="header-item header-column-3">First name</th>
                <th class="header-item header-column-4">Last name</th>
                <th class="header-item header-column-5">Email</th>
                <th class="header-item header-column-6">Phone no</th>
                <th class="header-item header-column-7 text-center">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('status')"
                    >Status</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'status'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'status'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('add_by')"
                    >Created by</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'add_by'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'add_by'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('created_at')"
                    >Created</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'created_at'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'created_at'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in getProjectsListPaginate.data">
                <tr :key="(row, index)">
                  <td
                    @click="toggle(index)"
                    :class="{ opened: opened.includes(index) }"
                  >
                    <span class="text-info pl-2"
                      ><i class="fas fa-outdent"></i
                    ></span>
                  </td>
                  <td class="column-1">{{ row.id }}</td>
                  <td class="column-2">{{ row.sh_business_name }}</td>
                  <td class="column-3">{{ row.first_name }}</td>
                  <td class="column-4">{{ row.last_name }}</td>
                  <td class="column-5">{{ row.email }}</td>
                  <td class="column-6">{{ row.mobile_no }}</td>
                  <td class="column-7">
                    <div v-if="row" class="mt500">
                      <b-progress
                        height="10px"
                        :value="fnGetProjectProgress(row.products)"
                        variant="success"
                        class="mb-1"
                      ></b-progress>
                    </div>
                  </td>
                  <td class="column-8">
                    {{ row.add_user }}
                  </td>
                  <td class="column-9">
                    {{ row.created_at | dateConvertYYYYMMDD }}
                  </td>
                </tr>
                <tr v-if="opened.includes(index)">
                  <td colspan="11">
                    <div class="row mr-2 ml-2">
                      <div class="col-lg-6 col-md-6 col-12">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th colspan="4" class="text-info text-center">
                                Assigned Checks
                              </th>
                            </tr>
                            <tr>
                              <th>Type</th>
                              <th class="text-right">Fee ($)</th>
                              <th class="text-center">Status</th>
                              <th class="text-center">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="product in row.products"
                              :key="product.id"
                            >
                              <th
                                class="column-1 fs-900 fw-600 pl-2 text-black-70"
                              >
                                {{ product.name }}
                              </th>
                              <td
                                class="column-1 fs-800 fw-600 pl-2 text-black-50 text-right"
                              >
                                {{ product.unit_price }}
                              </td>
                              <td class="column-2 text-center">
                                <span
                                  v-if="product.status == 1"
                                  class="badge badge-secondary"
                                  >Draft</span
                                >
                                <span
                                  v-else-if="product.status == 2"
                                  class="badge badge-primary"
                                  >Submit</span
                                >
                                <span
                                  v-else-if="product.status == 3"
                                  class="badge badge-warning"
                                  >MIR</span
                                >
                                <span
                                  v-else-if="product.status == 4"
                                  class="badge badge-info"
                                  >WFPR</span
                                >
                                <span
                                  v-else-if="product.status == 5"
                                  class="badge badge-success"
                                  >Completed</span
                                >
                                <span
                                  v-else-if="product.status == 6"
                                  class="badge badge-dark"
                                  >Cancelled</span
                                >
                                <span
                                  v-else-if="product.status == 7"
                                  class="badge badge-light"
                                  >Ready to Check</span
                                >
                                <span v-else class="badge badge-dark"
                                  >Not Started</span
                                >
                              </td>
                              <td>
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <!-- View job -->
                                  <a
                                    v-if="
                                      product.status &&
                                      (!canViewUserData ||
                                        fnReferenceDataCanView(product.id))
                                    "
                                    v-scroll-to="'#checkView'"
                                    @click="
                                      fnViewCheck(row, product.id, 'view')
                                    "
                                    class="d-inline pr-1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View"
                                  >
                                    <i class="far fa-eye text-info fs-1200"></i>
                                  </a>

                                  <!-- Edit job -->
                                  <button
                                    v-if="
                                      (cvs_all_can_access() ||
                                        fnReferenceDataCanEdit(product)) &&
                                      product.status
                                    "
                                    v-scroll-to="'#checkView'"
                                    class="btn btn-sm d-inline pr-1"
                                    @click="
                                      fnGoProductEdit(row, product.id, 'edit')
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit"
                                  >
                                    <i
                                      class="far fa-edit text-info fs-1200"
                                    ></i>
                                  </button>

                                  <!-- Start  Reference check -->
                                  <!-- Add  Reference check -->
                                  <button
                                    v-if="fnReferenceDataCanAdd(product)"
                                    v-scroll-to="'#checkView'"
                                    class="btn btn-sm d-inline pr-1"
                                    @click="
                                      fnGoProductAdd(row, product.id, 'new')
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Start"
                                  >
                                    <i
                                      class="far fa-plus-square text-info fs-1200"
                                    ></i>
                                  </button>
                                  <!-- End Reference check -->

                                  <!-- download certificate -->
                                  <button
                                    v-if="product.status && !canViewCertificate"
                                    v-scroll-to="'#checkView'"
                                    class="btn btn-sm d-inline pr-1"
                                    @click="
                                      fnGoCertificate(
                                        row,
                                        product.id,
                                        'certificate'
                                      )
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Certificate"
                                  >
                                    <i
                                      class="fas fa-file-invoice text-info fs-1200"
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <dl class="row">
                          <dt class="col-sm-4 text-black-60 fw-600">Notes</dt>
                          <dd class="col-sm-8">
                            {{ row.note }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="11">
                  <div class="sits-pagination-row">
                    <div class="items-per-page">
                      <div class="pr-2">
                        <p class="mb-0">Items per page</p>
                      </div>
                      <div class="pr-5">
                        <select
                          v-model="paginate"
                          class="form-control sits_select"
                        >
                          <option value="2">2</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <pagination
                        :limit="5"
                        :data="getProjectsListPaginate"
                        @pagination-change-page="fnPaginate"
                      ></pagination>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="checkView">
          <!-- Police check view -->
          <div
            class="row"
            v-if="
              (productId == 1 || productId == 2) &&
              action == 'view' &&
              getPoliceCheckSingle
            "
          >
            <div class="col-lg-9 col-md-9 col-12">
              <div>
                <appReviewBlock1 :propsData="getPoliceCheckSingle">
                </appReviewBlock1>

                <!-- This will show if this police check send by Individual user -->
                <div
                  v-if="
                    getPoliceCheckSingle.sh_id == 1 ||
                    getPoliceCheckSingle.sh_id == 3 ||
                    getPoliceCheckSingle.sh_id == null
                  "
                >
                  <appReviewBlock2 :propsData="getPoliceCheckSingle">
                  </appReviewBlock2>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
              <!-- check list section for CVS-->
              <div v-if="getPoliceCheckSingle && cvs_all_can_access() == true">
                <appCheckList1
                  :policeCheckId="getPoliceCheckSingle.id"
                  :propsData="getPoliceCheckSingle"
                ></appCheckList1>
              </div>

              <!-- Status update -->
              <div class="card" v-if="cvs_all_can_access() == true">
                <div class="card-header">
                  <h5 class="text-info mb-0">Status Change</h5>
                </div>
                <div class="card-body">
                  <statusChangeCom
                    :id="getPoliceCheckSingle.id"
                    :projectId="getPoliceCheckSingle.project_id"
                    :productId="getPoliceCheckSingle.product_id"
                    :status="getPoliceCheckSingle.status"
                    @fnUpdateStatus="updatePoliceCheckStatus"
                  ></statusChangeCom>
                </div>
              </div>

              <!-- Admin/Staff can view notes -->
              <checkListNotesCom
                v-if="cvs_all_can_access()"
                :noteType="'private'"
                :checkId="getPoliceCheckSingle.id"
                :productId="getPoliceCheckSingle.product_id"
              ></checkListNotesCom>

              <!-- Any other can view notes -->
              <checkListNotesPublicCom
                :noteType="'public'"
                :checkId="getPoliceCheckSingle.id"
                :productId="getPoliceCheckSingle.product_id"
              ></checkListNotesPublicCom>
            </div>
          </div>

          <!-- Police check Edit -->
          <div
            v-if="
              (productId == 1 || productId == 2) &&
              action == 'edit' &&
              getPoliceCheckSingle
            "
          >
            <appPoliceCheckStepsEditByAdmin
              :policeCheckId="getPoliceCheckSingle.id"
              :product_id="productId"
              :propsData="getPoliceCheckSingle"
              :propsNotesData="getNotesList"
            >
            </appPoliceCheckStepsEditByAdmin>
          </div>

          <!-- Police check Certificate-->
          <div
            v-if="(productId == 1 || productId == 2) && action == 'certificate'"
          >
            <policeCheckCertificate
              :urlCertification="urlCertificationPoliceCheck"
            ></policeCheckCertificate>
          </div>

          <!-- Right to Work in Australia Check -->
          <div v-if="productId == 3 && action == 'view'">
            <appRightToWorkInAustraliaView
              :listCategory="'project'"
              :propsData1="getUserFromId"
              :propsData2="getRightWorkAustralia"
            ></appRightToWorkInAustraliaView>
          </div>
          <div v-if="productId == 3 && action == 'edit'">
            <appRightToWorkInAustraliaEdit
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appRightToWorkInAustraliaEdit>
          </div>
          <div v-if="productId == 3 && action == 'certificate'">
            <appRightToWorkInAustraliaCertificate
              :propsData1="getUserFromId"
              :propsData2="getRightWorkAustralia"
            ></appRightToWorkInAustraliaCertificate>
          </div>

          <!-- Academic Qualification Check -->
          <div v-if="productId == 5 && action == 'view'">
            <appCheckAcademicQualificationView
              :listCategory="'project'"
              :propsData1="getUserFromId"
              :propsData2="getAcademicQualification"
            ></appCheckAcademicQualificationView>
          </div>
          <div v-if="productId == 5 && action == 'edit'">
            <appCheckAcademicQualificationEdit
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appCheckAcademicQualificationEdit>
          </div>
          <div v-if="productId == 5 && action == 'certificate'">
            <appCheckAcademicQualificationCertificate
              :propsData1="getUserFromId"
              :propsData2="getAcademicQualification"
            ></appCheckAcademicQualificationCertificate>
          </div>

          <!-- Professional Membership Check -->
          <div v-if="productId == 6 && action == 'view'">
            <appCheckProfessionalMembershipView
              :listCategory="'project'"
              :propsData1="getUserFromId"
              :propsData2="getProfessionalMembership"
            ></appCheckProfessionalMembershipView>
          </div>
          <div v-if="productId == 6 && action == 'edit'">
            <appCheckProfessionalMembershipEdit
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appCheckProfessionalMembershipEdit>
          </div>
          <div v-if="productId == 6 && action == 'certificate'">
            <appCheckProfessionalMembershipCertificate
              :propsData1="getUserFromId"
              :propsData2="getProfessionalMembership"
            ></appCheckProfessionalMembershipCertificate>
          </div>

          <!-- Employment History Check -->
          <div v-if="productId == 7 && action == 'view'">
            <appCheckEmploymentHistoryView
              :listCategory="'project'"
              :propsData1="getUserFromId"
              :propsData2="getEmploymentHistory"
            ></appCheckEmploymentHistoryView>
          </div>
          <div v-if="productId == 7 && action == 'edit'">
            <appCheckEmploymentHistoryEdit
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appCheckEmploymentHistoryEdit>
          </div>
          <div v-if="productId == 7 && action == 'certificate'">
            <appCheckEmploymentHistoryCertificate
              :propsData1="getUserFromId"
              :propsData2="getEmploymentHistory"
            ></appCheckEmploymentHistoryCertificate>
          </div>

          <!-- Bankruptcy Record Check -->
          <div v-if="productId == 8 && action == 'view'">
            <appCheckBankruptcyView
              :listCategory="'project'"
              :propsData1="getUserFromId"
              :propsData2="getBankruptcyRecord"
            ></appCheckBankruptcyView>
          </div>
          <div v-if="productId == 8 && action == 'edit'">
            <appCheckBankruptcyEdit
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appCheckBankruptcyEdit>
          </div>
          <div v-if="productId == 8 && action == 'certificate'">
            <appCheckBankruptcyCertificate
              :propsData1="getUserFromId"
              :propsData2="getBankruptcyRecord"
            ></appCheckBankruptcyCertificate>
          </div>

          <!-- Drivers Licence and Traffic Offence Check -->
          <div v-if="productId == 9 && action == 'view'">
            <appCheckDriversLicenceTrafficOffenceView
              :listCategory="'project'"
              :propsData1="getUserFromId"
              :propsData2="getDriversOffences"
            ></appCheckDriversLicenceTrafficOffenceView>
          </div>
          <div v-if="productId == 9 && action == 'edit'">
            <appCheckDriversLicenceTrafficOffenceEdit
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appCheckDriversLicenceTrafficOffenceEdit>
          </div>
          <div v-if="productId == 9 && action == 'certificate'">
            <appCheckDriversLicenceTrafficOffencCertificate
              :propsData1="getUserFromId"
              :propsData2="getDriversOffences"
            ></appCheckDriversLicenceTrafficOffencCertificate>
          </div>

          <!-- Reference check -->
          <div v-if="(productId == 4 || productId == 10) && action == 'new'">
            <appCheckReferenceAdd
              :mode="action"
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appCheckReferenceAdd>
          </div>
          <div v-if="(productId == 4 || productId == 10) && action == 'view'">
            <appCheckReferenceView
              :listCategory="'project'"
              :propsData1="getUserFromId"
              :propsData2="getReference"
            ></appCheckReferenceView>
          </div>
          <div v-if="(productId == 4 || productId == 10) && action == 'edit'">
            <appCheckReferenceEdit
              :mode="action"
              :projectId="projectId"
              :productId="productId"
              :userId="userId"
            ></appCheckReferenceEdit>
          </div>
          <div
            v-if="
              (productId == 4 || productId == 10) && action == 'certificate'
            "
          >
            <appCheckReferenceCertificate
              :propsData1="getUserFromId"
              :propsData2="getReference"
            ></appCheckReferenceCertificate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import pagination from "laravel-vue-pagination";
import store from "../../store/modules/base.module";
import * as messageService from "../../services/message.service";
import * as authService from "../../services/auth.service";
import * as policeCheckService from "../../services/policeCheck.service";

import appReviewBlock1 from "../../components/policeCheck/reviewBlock1.vue";
import appReviewBlock2 from "../../components/policeCheck/reviewBlock2.vue";
import appCheckList1 from "../policeCheck/pcCheckListCom.vue";
import checkListNotesCom from "../notes/CheckListNotesCom.vue";
import checkListNotesPublicCom from "../notes/CheckListNotesPublicCom.vue";
import appPoliceCheckStepsEditByAdmin from "../../components/policeCheck/policeCheckStepsEditByAdmin.vue";
import policeCheckCertificate from "../../components/policeCheck/policeCheckCertificate.vue";
import statusChangeCom from "../../components/common/StatusChangeCom.vue";

import appCheckAcademicQualificationView from "../../components/checkAcademicQualification/checkAcademicQualificationView.vue";
import appCheckBankruptcyView from "../../components/checkBankruptcy/checkBankruptcyView.vue";
import appCheckDriversLicenceTrafficOffenceView from "../../components/checkDriversLicenceTrafficOffence/checkDriversLicenceTrafficOffenceView.vue";
import appCheckEmploymentHistoryView from "../../components/checkEmploymentHistory/checkEmploymentHistoryView.vue";
import appCheckProfessionalMembershipView from "../../components/checkProfessionalMembership/checkProfessionalMembershipView.vue";
import appRightToWorkInAustraliaView from "../../components/checkRightToWorkInAustralia/RightToWorkInAustraliaView.vue";

import appCheckAcademicQualificationEdit from "../../views/checkAcademicQualification/CheckAcademicQualificationEdit.vue";
import appRightToWorkInAustraliaEdit from "../../views/checkRightToWorkInAustralia/RightToWorkInAustraliaEdit.vue";
import appCheckBankruptcyEdit from "../../views/checkBankruptcy/CheckBankruptcyEdit.vue";
import appCheckProfessionalMembershipEdit from "../../views/checkProfessionalMembership/CheckProfessionalMembershipEdit.vue";
import appCheckEmploymentHistoryEdit from "../../views/checkEmploymentHistory/CheckEmploymentHistoryEdit.vue";
import appCheckDriversLicenceTrafficOffenceEdit from "../../views/checkDriversLicenceTrafficOffence/CheckDriversLicenceTrafficOffenceEdit.vue";

import appCheckAcademicQualificationCertificate from "../../components/checkAcademicQualification/checkAcademicQualificationCertificate.vue";
import appRightToWorkInAustraliaCertificate from "../checkRightToWorkInAustralia/RightToWorkInAustraliaCertificate.vue";
import appCheckBankruptcyCertificate from "../../components/checkBankruptcy/checkBankruptcyCertificate.vue";
import appCheckProfessionalMembershipCertificate from "../../components/checkProfessionalMembership/checkProfessionalMembershipCertificate.vue";
import appCheckEmploymentHistoryCertificate from "../../components/checkEmploymentHistory/checkEmploymentHistoryCertificate.vue";
import appCheckDriversLicenceTrafficOffencCertificate from "../../components/checkDriversLicenceTrafficOffence/checkDriversLicenceTrafficOffenceCertificate.vue";

import appCheckReferenceAdd from "../../components/checkReference/checkReference.vue";
import appCheckReferenceView from "../../components/checkReference/checkReferenceView.vue";
import appCheckReferenceEdit from "../../views/checkReference/CheckReferenceEdit.vue";
import appCheckReferenceCertificate from "../../components/checkReference/checkReferenceCertificate.vue";

export default {
  name: "CustomerList",
  props: ["userType"],
  components: {
    pagination,
    appReviewBlock1,
    appReviewBlock2,
    appCheckList1,
    checkListNotesCom,
    checkListNotesPublicCom,
    appPoliceCheckStepsEditByAdmin,
    policeCheckCertificate,
    statusChangeCom,

    appCheckAcademicQualificationView,
    appCheckBankruptcyView,
    appCheckDriversLicenceTrafficOffenceView,
    appCheckEmploymentHistoryView,
    appCheckProfessionalMembershipView,
    appRightToWorkInAustraliaView,

    appCheckAcademicQualificationEdit,
    appRightToWorkInAustraliaEdit,
    appCheckBankruptcyEdit,
    appCheckProfessionalMembershipEdit,
    appCheckEmploymentHistoryEdit,
    appCheckDriversLicenceTrafficOffenceEdit,

    appCheckAcademicQualificationCertificate,
    appRightToWorkInAustraliaCertificate,
    appCheckBankruptcyCertificate,
    appCheckProfessionalMembershipCertificate,
    appCheckEmploymentHistoryCertificate,
    appCheckDriversLicenceTrafficOffencCertificate,

    appCheckReferenceAdd,
    appCheckReferenceView,
    appCheckReferenceEdit,
    appCheckReferenceCertificate,
  },
  data() {
    return {
      opened: [],
      isLoaderStatusChange: false,
      loginUserObj: {},
      policeCheckSingle: {},
      paginate: 15,
      currentPage: 1,
      cm_id: "",
      search: "",
      fromDate: "",
      toDate: "",
      sortDirection: "desc",
      sortField: "id",
      isExport: true,

      projectId: "",
      userId: "",
      productId: "",
      action: "",
      canViewCertificate: false,
      canViewUserData: false,
      urlCertificationPoliceCheck: "",
    };
  },
  computed: {
    ...mapGetters([
      "getProjectsListPaginate",
      "getProjectsListPaginateCurrent",
      "getUserListPaginate",
      "getAcademicQualification",
      "getBankruptcyRecord",
      "getDriversOffences",
      "getEmploymentHistory",
      "getProfessionalMembership",
      "getRightWorkAustralia",
      "getUserFromId",
      "getNotesList",
      "getPoliceCheckSingle",
      "getStakeholderSettings",
      "getReference",
    ]),
    paginateObj() {
      let obj = {};
      obj = {
        currentPage: this.currentPage,
        paginate: this.paginate,
        cm_id: this.cm_id,
        search: this.search,
        fromDate: this.fromDate,
        toDate: this.toDate,
        sortDirection: this.sortDirection,
        sortField: this.sortField,
      };
      return obj;
    },
  },
  methods: {
    ...mapActions([
      "fetchProjectsListPaginate",
      "fetchUserListPaginate",
      "fetchAcademicQualification",
      "fetchBankruptcyRecord",
      "fetchDriversOffences",
      "fetchEmploymentHistory",
      "fetchProfessionalMembership",
      "fetchRightWorkAustralia",
      "fetchUserFromId",
      "fetchNotesList",
      "fetchNotesPublicList",
      "fetchPoliceCheckSingleByProject",
      "fetchStakeholderSettings",
      "fetchReference",
      "fetchProjectsSingle",
    ]),

    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },

    fnChangeSort(field) {
      if (this.sortField == field) {
        this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
      } else {
        this.sortField = field;
      }
      this.fetchProjectsListPaginate(this.paginateObj);
    },

    fnPaginate: async function (currentPage = 1) {
      try {
        this.currentPage = currentPage;
        this.fetchProjectsListPaginate(this.paginateObj);
      } catch (error) {
        const obj = {
          type: "error",
          title: error.response.data.error.name,
          text: error.response.data.error.message,
          footer: "Error code: " + error.response.data.error.statusCode,
        };

        messageService.fnSweetAlertDisplay(obj);
      }
    },

    // Export list Summary into excel
    getExportListSummary: function () {
      window.open(
        `${store.state.apiURL}/project-admin/list-export?q=${this.q}&from_date=${this.fromDate}&to_date=${this.toDate}&userType=${this.userType}&cm_id=${this.loginUserObj.parent_id}`
      );

      messageService.fnSweetAlertSuccessToast(
        "Project Summary",
        "Downloaded successfully"
      );
    },

    fnSearch() {
      this.isExport = true;
      this.fnPaginate();
      this.isExport = false;
      this.fetchProjectsListPaginate(this.paginateObj);
    },

    // EXport Police check list summary
    fnExport() {
      this.getExportListSummary();
      this.isExport = true;
    },
    // To Clear Search keys
    fnClear() {
      this.currentPage = 1;
      this.search = "";
      this.selectedStatus = "";
      this.fromDate = "";
      this.toDate = "";
      this.sortDirection = "desc";
      this.sortField = "id";
      this.isExport = true;
      this.fetchProjectsListPaginate(this.paginateObj);
    },

    /**
     * CVS parties can access
     * Admin/Staff
     */
    cvs_all_can_access() {
      if (this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2) {
        return true;
      }
      return false;
    },
    /**
     * Corporate Manager parties can access
     * Corporate Manager/Corporate Staff
     */
    cm_all_can_access() {
      if (this.loginUserObj.role_id == 4 || this.loginUserObj.role_id == 5) {
        return true;
      }
      return false;
    },

    /**
     * Corporate Manager/ staff can view if product id 4 or 10
     */
    fnReferenceDataCanView(productId) {
      if (productId == 4 || productId == 10) {
        return true;
      }
      return false;
    },

    /**
     * Corporate Manager/ staff can Edit if product id 4 or 10 and status 1 (save)
     */
    fnReferenceDataCanEdit(product) {
      if ((product.id == 4 || product.id == 10) && product.status == 1) {
        return true;
      }
      return false;
    },

    /**
     * Corporate Manager/ staff can Add if product id 4 or 10 and status null, 0 or empty
     */
    fnReferenceDataCanAdd(product) {
      if (
        (product.id == 4 || product.id == 10) &&
        (product.status == null || product.status == 0 || product.status == "")
      ) {
        return true;
      }
      return false;
    },

    fnGetProjectProgress(products) {
      let count = 0;
      let numProjects = 0;
      if (products.length > 0) {
        numProjects = products.length;
        for (let i = 0; i < products.length; i++) {
          if (products[i].status == 5) {
            count = count + 1;
          }
        }
      }
      return (count * 100) / numProjects;
    },

    /**
     * Add selected check start form corporate manager and end user can't look at
     * Eg.
     *    Reference Check
     *    Validated Reference Check
     * @param pro - Check id (Project id)
     * @param productId - product id
     */
    async fnGoProductAdd(pro, productId, action) {
      this.projectId = pro.id;
      this.userId = pro.user_id;
      this.productId = productId;
      this.action = action;

      if (productId == 4) {
        // on future development
      } else if (productId == 10) {
        this.fetchUserFromId(pro.user_id);
        this.fetchAcademicQualification(pro.id);
      }
    },

    /**
     * Edit Each check
     * @param pro - Check id (Project id)
     * @param productId - product id
     * @param action - action via view|edit|certificate
     */
    async fnGoProductEdit(pro, productId, action) {
      this.projectId = pro.id;
      this.userId = pro.user_id;
      this.productId = productId;
      this.action = action;

      if (productId == 1 || productId == 2) {
        /**
         * Get the Police check id form check_police_standard
         * Get the Police check id form check_police_volunteer
         */
        const obj = {
          projectId: pro.id,
          productId: this.productId,
        };

        await this.fetchPoliceCheckSingleByProject(obj);

        if (this.getPoliceCheckSingle == null) {
          const obj = {
            type: "info",
            title: "Application Information",
            text: "It is not available yet",
          };

          messageService.fnSweetAlertDisplay(obj);
        }
      } else if (productId == 3) {
        this.fetchUserFromId(pro.user_id);
        this.fetchRightWorkAustralia(pro.id);
      } else if (productId == 4 || productId == 10) {
        this.fetchUserFromId(pro.user_id);
        this.fetchReference({ projectId: pro.id, productId: productId });
      } else if (productId == 5) {
        this.fetchUserFromId(pro.user_id);
        this.fetchAcademicQualification(pro.id);
      } else if (productId == 6) {
        this.fetchUserFromId(pro.user_id);
        this.fetchProfessionalMembership(pro.id);
      } else if (productId == 7) {
        this.fetchUserFromId(pro.user_id);
        this.fetchEmploymentHistory(pro.id);
      } else if (productId == 8) {
        this.fetchUserFromId(pro.user_id);
        this.fetchBankruptcyRecord(pro.id);
      } else if (productId == 9) {
        this.fetchUserFromId(pro.user_id);
        this.fetchDriversOffences(pro.id);
      }
    },

    /**
     * View Each check
     * @param pro - Check id
     * @param productId - product id
     * @param action - action via view|edit|certificate
     */
    async fnViewCheck(pro, productId, action) {
      this.productId = productId;
      this.action = action;

      if (productId == 1 || productId == 2) {
        /**
         * Get the Police check id form check_police_standard
         * Get the Police check id form check_police_volunteer
         */
        const obj = {
          projectId: pro.id,
          productId: this.productId,
        };

        await this.fetchPoliceCheckSingleByProject(obj);

        if (this.getPoliceCheckSingle == null) {
          const obj = {
            type: "info",
            title: "Application Information",
            text: "It is not available yet",
          };

          messageService.fnSweetAlertDisplay(obj);
        }
      } else if (productId == 3) {
        this.fetchUserFromId(pro.user_id);
        this.fetchRightWorkAustralia(pro.id);

        // To load relevant notes
        const obj = {
          idType: "project_id",
          checkOrProjectId: pro.id,
          productId: 3,
        };
        this.fetchNotesList(obj);
        this.fetchNotesPublicList(obj);
      } else if (productId == 4) {
        this.fetchUserFromId(pro.user_id);
        this.fetchReference({ projectId: pro.id, productId: 4 });
        // To load relevant notes
        const obj = {
          idType: "project_id",
          checkOrProjectId: pro.id,
          productId: 4,
        };
        this.fetchNotesList(obj);
        this.fetchNotesPublicList(obj);
      } else if (productId == 10) {
        this.fetchUserFromId(pro.user_id);
        this.fetchReference({ projectId: pro.id, productId: 10 });
        // To load relevant notes
        const obj = {
          idType: "project_id",
          checkOrProjectId: pro.id,
          productId: 10,
        };
        this.fetchNotesList(obj);
        this.fetchNotesPublicList(obj);
      } else if (productId == 5) {
        this.fetchUserFromId(pro.user_id);
        this.fetchAcademicQualification(pro.id);
        // To load relevant notes
        const obj = {
          idType: "project_id",
          checkOrProjectId: pro.id,
          productId: 5,
        };
        this.fetchNotesList(obj);
        this.fetchNotesPublicList(obj);
      } else if (productId == 6) {
        this.fetchUserFromId(pro.user_id);
        this.fetchProfessionalMembership(pro.id);
        // To load relevant notes
        const obj = {
          idType: "project_id",
          checkOrProjectId: pro.id,
          productId: 6,
        };
        this.fetchNotesList(obj);
        this.fetchNotesPublicList(obj);
      } else if (productId == 7) {
        this.fetchUserFromId(pro.user_id);
        this.fetchEmploymentHistory(pro.id);
        // To load relevant notes
        const obj = {
          idType: "project_id",
          checkOrProjectId: pro.id,
          productId: 7,
        };
        this.fetchNotesList(obj);
        this.fetchNotesPublicList(obj);
      } else if (productId == 8) {
        this.fetchUserFromId(pro.user_id);
        this.fetchBankruptcyRecord(pro.id);
        // To load relevant notes
        const obj = {
          idType: "project_id",
          checkOrProjectId: pro.id,
          productId: 8,
        };
        this.fetchNotesList(obj);
        this.fetchNotesPublicList(obj);
      } else if (productId == 9) {
        this.fetchUserFromId(pro.user_id);
        this.fetchDriversOffences(pro.id);
        // To load relevant notes
        const obj = {
          idType: "project_id",
          checkOrProjectId: pro.id,
          productId: 9,
        };
        this.fetchNotesList(obj);
        this.fetchNotesPublicList(obj);
      }
    },

    /**
     * Download or print Each check Certificate
     * @param pro - Check id
     * @param productId - product id
     * @param action - action via view|edit|certificate
     */
    async fnGoCertificate(pro, productId, action) {
      this.projectId = pro.id;
      this.userId = pro.user_id;
      this.productId = productId;
      this.action = action;

      if (productId == 1 || productId == 2) {
        /**
         * Get the Police check id form check_police_standard
         * Get the Police check id form check_police_volunteer
         */
        const obj = {
          projectId: this.projectId,
          productId: this.productId,
        };

        await this.fetchPoliceCheckSingleByProject(obj);

        if (
          this.getPoliceCheckSingle == null ||
          this.getPoliceCheckSingle.police_check_certificate_from_nss ==
            undefined
        ) {
          const obj = {
            type: "error",
            title: "Certificate",
            text: "It is not available yet",
          };

          messageService.fnSweetAlertDisplay(obj);

          return;
        }

        this.urlCertificationPoliceCheck =
          this.getPoliceCheckSingle.police_check_certificate_from_nss;
      } else if (productId == 3) {
        this.fetchUserFromId(pro.user_id);
        this.fetchRightWorkAustralia(pro.id);
      } else if (productId == 4) {
        this.fetchUserFromId(pro.user_id);
        this.fetchReference({ projectId: pro.id, productId: 4 });
      } else if (productId == 10) {
        this.fetchUserFromId(pro.user_id);
        this.fetchReference({ projectId: pro.id, productId: 10 });
      } else if (productId == 5) {
        this.fetchUserFromId(pro.user_id);
        this.fetchAcademicQualification(pro.id);
      } else if (productId == 6) {
        this.fetchUserFromId(pro.user_id);
        this.fetchProfessionalMembership(pro.id);
      } else if (productId == 7) {
        this.fetchUserFromId(pro.user_id);
        this.fetchEmploymentHistory(pro.id);
      } else if (productId == 8) {
        this.fetchUserFromId(pro.user_id);
        this.fetchBankruptcyRecord(pro.id);
      } else if (productId == 9) {
        this.fetchUserFromId(pro.user_id);
        this.fetchDriversOffences(pro.id);
      }
    },

    /**
     * Update police check status
     * This one need to relocated in to view component later
     */
    updatePoliceCheckStatus: async function (obj) {
      try {
        this.isLoaderStatusChange = true;
        this.errors = {};

        await policeCheckService.updateStatus(
          obj.id, // Police check id
          { status: obj.new_status } // new Status
        );

        /**
         * Get police check single based on Project id and Product id
         */
        const objSingle = {
          projectId: obj.project_id,
          productId: obj.product_id,
        };
        await this.fetchProjectsListPaginate(this.paginateObj);
        await this.fetchPoliceCheckSingleByProject(objSingle);

        messageService.fnToastSuccess(
          "Police check status - Successfully updated"
        );

        this.isLoaderStatusChange = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Police check status",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Police check status",
              "Something wrong"
            );
            break;
        }
        this.isLoaderStatusChange = false;

        console.log("updatePoliceCheckStatusModal", error);
      }
    },
  },
  async created() {
    this.loginUserObj = authService.getUserFromToken();
    await this.fetchStakeholderSettings(this.loginUserObj.parent_id);
    await this.fetchProjectsListPaginate(this.paginateObj);
  },
  watch: {
    getStakeholderSettings(val) {
      if (
        val &&
        val.settings &&
        val.settings.allow_end_customer_to_view_and_notify_check_complete &&
        val.settings.allow_end_customer_to_view_and_notify_check_complete == 1
      ) {
        this.canViewCertificate = true;
      }

      if (
        val &&
        val.settings &&
        val.settings.hide_view_end_customer_data &&
        val.settings.hide_view_end_customer_data == 1
      ) {
        this.canViewUserData = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sits-btn-round.btn.btn-info {
  border-radius: 50% !important;
  padding-top: 0.2rem !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
</style>
