<template>
  <div class="pt-3">
    <appCheckBankruptcy
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckBankruptcy>
  </div>
</template>
<script>
import appCheckBankruptcy from "../../components/checkBankruptcy/checkBankruptcy.vue";
export default {
  name: "CheckBankruptcyEdit",
  props: ["projectId", "productId", "userId"],

  components: {
    appCheckBankruptcy,
  },
};
</script>