<template>
  <div class="card-columns">
    <appCheckDetails :propsCheckDetails="propsData2"></appCheckDetails>
    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Personal details</h5>
      </div>
      <div class="card-body">
        <dl class="row" v-if="propsData1">
          <dd class="col-sm-5 text-black-60 fw-600">Given name</dd>
          <dd class="col-sm-7">
            {{ propsData1.first_name }}
          </dd>
          <dd class="col-sm-5 text-black-60 fw-600">Middle name</dd>
          <dd class="col-sm-7">{{ propsData1.middle_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Family name</dd>
          <dd class="col-sm-7">{{ propsData1.last_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Date of birth</dd>
          <dd class="col-sm-7">{{ propsData1.date_of_birth }}</dd>
        </dl>
      </div>
    </div>

    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Licence details</h5>
      </div>

      <div class="card-body">
        <dl class="row" v-if="propsData2">
          <dd class="col-sm-5 text-black-60 fw-600">Licence Number</dd>
          <dd class="col-sm-7">
            {{ propsData2.licence_number }}
          </dd>
          <dd class="col-sm-5 text-black-60 fw-600">State of Licence</dd>
          <dd class="col-sm-7">{{ propsData2.state_code }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Copy of Licence</dd>
          <dd class="col-sm-7">
            <wdImageAvatarPreview
              :title="'Copy of Licence'"
              :coverImgUrl="propsData2.doc_urls"
              :fontAwClass="'fas fa-file-alt'"
            ></wdImageAvatarPreview>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import appCheckDetails from "../../components/project/checkDetails.vue";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";
export default {
  name: "checkRightToWorkInAustraliaView",
  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    appCheckDetails,
    wdImageAvatarPreview,
  },
};
</script>