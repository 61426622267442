<template>
  <div class="card-columns">
    <appCheckDetails :propsCheckDetails="propsData2"></appCheckDetails>
    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Personal details</h5>
      </div>
      <div class="card-body">
        <dl class="row" v-if="propsData1">
          <dd class="col-sm-5 text-black-60 fw-600">Given name</dd>
          <dd class="col-sm-7">
            {{ propsData1.first_name }}
          </dd>
          <dd class="col-sm-5 text-black-60 fw-600">Middle name</dd>
          <dd class="col-sm-7">{{ propsData1.middle_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Family name</dd>
          <dd class="col-sm-7">{{ propsData1.last_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Date of birth</dd>
          <dd class="col-sm-7">{{ propsData1.date_of_birth }}</dd>
        </dl>
      </div>
    </div>

    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Academic qualification</h5>
      </div>

      <div class="card-body">
        <div class="accordion" role="tablist">
          <div v-for="(item, index) in academicQualification" :key="index">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  href="#"
                  v-b-toggle="'accordion-' + index"
                  variant="secondary"
                  class="text-left"
                  >{{ item.name_of_organisation }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'accordion-' + index"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text v-html="item.note"></b-card-text>
                  <div class="mb-1" :key="index">
                    <dl class="row">
                      <dd class="col-sm-5 text-black-60 fw-600">First name</dd>
                      <dd class="col-sm-7">
                        {{ item.first_name }}
                      </dd>
                      <dd class="col-sm-5 text-black-60 fw-600">Last name</dd>
                      <dd class="col-sm-7">{{ item.last_name }}</dd>
                      <dd class="col-sm-5 text-black-60 fw-600">
                        Qualification obtained
                      </dd>
                      <dd class="col-sm-7">
                        {{ item.qualification_obtained }}
                      </dd>
                      <dd class="col-sm-5 text-black-60 fw-600">
                        Date qualification obtained
                      </dd>
                      <dd class="col-sm-7">
                        {{ item.date_qualification_obtained }}
                      </dd>
                      <dd class="col-sm-5 text-black-60 fw-600">
                        Name of organisation
                      </dd>
                      <dd class="col-sm-7">{{ item.name_of_organisation }}</dd>
                    </dl>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appCheckDetails from "../../components/project/checkDetails.vue";
export default {
  name: "checkAcademicQualificationView",
  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    appCheckDetails,
  },
  computed: {
    academicQualification() {
      let x = [];
      if (
        this.propsData2 &&
        this.propsData2.academic_qualification_trans &&
        this.propsData2.academic_qualification_trans.length > 0
      ) {
        x = this.propsData2.academic_qualification_trans;
      }

      return x;
    },
  },
};
</script>