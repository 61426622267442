<template>
  <div class="card-columns">
    <appCheckDetails :propsCheckDetails="propsData2"></appCheckDetails>
    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Personal details</h5>
      </div>
      <div class="card-body">
        <dl class="row" v-if="propsData1">
          <dd class="col-sm-5 text-black-60 fw-600">Given name</dd>
          <dd class="col-sm-7">
            {{ propsData1.first_name }}
          </dd>
          <dd class="col-sm-5 text-black-60 fw-600">Middle name</dd>
          <dd class="col-sm-7">{{ propsData1.middle_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Family name</dd>
          <dd class="col-sm-7">{{ propsData1.last_name }}</dd>
          <dd class="col-sm-5 text-black-60 fw-600">Date of birth</dd>
          <dd class="col-sm-7">{{ propsData1.date_of_birth }}</dd>
        </dl>
      </div>
    </div>

    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Additional Details</h5>
      </div>
      <div class="card-body">
        <dl class="row mb-1">
          <dd class="col-sm-5 text-black-60 fw-600">
            Have maiden/previous/alias names
          </dd>
          <dd class="col-sm-7">
            {{ propsData2.previous_names_status }}
          </dd>
        </dl>
        <template v-for="(item, index) in propsData2.previous_names">
          <dl class="row mb-1" :key="index">
            <dd class="col-sm-5 text-black-60 fw-600">
              <div>
                <p class="mb-0">Name type</p>
              </div>
            </dd>
            <dd class="col-sm-7">
              {{ item.name_type }}
            </dd>
            <dd class="col-sm-5 text-black-60 fw-600">
              <div>
                <p class="mb-0">First name</p>
              </div>
            </dd>
            <dd class="col-sm-7">{{ item.first_name }}</dd>
            <dd class="col-sm-5 text-black-60 fw-600">Middle name</dd>
            <dd class="col-sm-7">{{ item.middle_name }}</dd>
            <dd class="col-sm-5 text-black-60 fw-600">Last name</dd>
            <dd class="col-sm-7">{{ item.last_name }}</dd>
            <dd class="col-sm-5 text-black-60 fw-600">Date of change</dd>
            <dd class="col-sm-7">{{ item.date_of_change }}</dd>
            <dd class="col-sm-12">
              <hr v-if="propsData2.previous_names.length > index + 1" />
            </dd>
          </dl>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import appCheckDetails from "../../components/project/checkDetails.vue";
export default {
  name: "checkBankruptcyView",
  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {
    appCheckDetails,
  },
};
</script>
