<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h4>Bankruptcy Record Check</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="step1.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="step1.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="step1.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
                <div class="invalid-feedback col-12" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.first_name == '' || step1.first_name == null"
                id="checkbox-1"
                name="checkbox-1"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_given_name"
              >
                Does not have a given name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_given_name"
              >
                {{ errors.have_a_given_name[0] }}
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.middle_name == '' || step1.middle_name == null"
                id="checkbox-2"
                name="checkbox-2"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_middle_name"
              >
                Does not have a middle name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_middle_name"
              >
                {{ errors.have_a_middle_name[0] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="date"
                  id="dateOfBirth"
                  autocomplete="off_dateOfBirth"
                  placeholder=" "
                  v-model="step1.date_of_birth"
                />
                <label class="floatingLabel" for="dateOfBirth"
                  >Date of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.date_of_birth"
                >
                  {{ errors.date_of_birth[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <p class="text-info">Additional Details</p>
            </div>
          </div>
          <div class="row">
            <div class="alert alert-danger mb-5 col-12" v-if="arrErr.length">
              <p
                class="fs-800 mb-0"
                v-for="(item, index) in arrErr"
                :key="index"
              >
                {{ item }}
              </p>
            </div>

            <div class="col-lg-6 col-md-6 col-12">
              <label for="last_name" class="d-inline pr-3"
                >I have maiden/previous/alias names</label
              >
              <b-form-radio-group
                id="i_have_a_previous_name"
                :options="optionsAliasNameYesNo"
                class="d-inline"
                v-model="step2.previous_names_status"
              ></b-form-radio-group>
            </div>
            <div class="col-lg-6 col-md-6 col-12 text-right">
              <appModalPreviousNamesNew
                :projectId="projectId"
                :productId="8"
                :previous_names_status="step2.previous_names_status"
                :previous_names="step2.previous_names"
                @fnCallRefresh="fnCallRefresh"
                v-if="step2.previous_names_status == 'Yes'"
              >
              </appModalPreviousNamesNew>
            </div>
          </div>

          <div
            class="row mt-2 mb-5"
            v-if="step2.previous_names_status == 'Yes'"
          >
            <div class="col-12 table-responsive">
              <table class="table table-bordered table-striped table-sm m-0">
                <thead>
                  <tr>
                    <th>Name type</th>
                    <th>First name</th>
                    <th>Middle name</th>
                    <th>Last name</th>
                    <th>Date of change</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in step2.previous_names"
                    :key="index"
                  >
                    <td>{{ item.name_type }}</td>
                    <td>{{ item.first_name }}</td>
                    <td>{{ item.middle_name }}</td>
                    <td>{{ item.last_name }}</td>
                    <td>{{ item.date_of_change }}</td>
                    <td>
                      <div>
                        <appModalPreviousNamesEdit
                          :projectId="projectId"
                          :productId="8"
                          :propsNameData="item"
                          :propsNameIndex="index"
                          :previous_names="step2.previous_names"
                          :previous_names_status="step2.previous_names_status"
                          class="d-inline"
                        >
                        </appModalPreviousNamesEdit>
                        <a
                          href="javascript: void(0)"
                          class="p-2 d-inline"
                          @click="fnDeletePreviousNames(index)"
                          ><i class="far fa-times-circle text-info"></i
                        ></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-right">
              <router-link
                v-if="loginUserObj.role_id == 9"
                class="btn btn-secondary btn-sm mr-2"
                :to="{ name: 'dashboardCC' }"
                >Cancel</router-link
              >
              <button
                v-if="can_cc_update()"
                class="btn btn-info btn-sm"
                @click="fnUpdate()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Submit
              </button>

              <button
                v-if="can_cvs_update()"
                class="btn btn-info btn-sm"
                @click="fnSave()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appStep2Ins></appStep2Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as chkBankruptcyRecordService from "../../services/chkBankruptcyRecord.service";
import appModalPreviousNamesNew from "../common/ModalPreviousNamesNew.vue";
import appModalPreviousNamesEdit from "../common/ModalPreviousNamesEdit.vue";
import * as messageService from "../../services/message.service";
import appStep2Ins from "../policeCheck/step2Ins.vue";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "checkBankruptcyCom",
  props: ["mode", "projectId", "productId", "userId"],
  components: {
    appModalPreviousNamesNew,
    appModalPreviousNamesEdit,
    appStep2Ins,
  },
  data() {
    return {
      loginUserObj: {},
      errors: {},
      arrErr: [],
      stateOfLicence: "",
      loading: false,
      step1: {
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        country_of_birth_code: "Australia",
        email: "",
        mobile_no: "",
        have_a_given_name: "Yes",
        have_a_middle_name: "Yes",
        have_a_family_name: "Yes",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        country_code: "",
      },
      step2: {
        previous_names_status: "No",
        previous_names: [],
        status: 1,
      },
      optionsAliasNameYesNo: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getBankruptcyRecord", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchBankruptcyRecord", "fetchUserFromId"]),

    fnUpdate: async function () {
      this.loading = true;
      this.fnCheckValidation();

      if (this.arrErr.length > 0) {
        return;
      }
      try {
        const res = await chkBankruptcyRecordService.updateUserInfo(
          this.userId,
          this.step1
        );

        this.step2.status = 2;
        const response = await chkBankruptcyRecordService.addOrUpdate(
          this.projectId,
          this.step2
        );

        this.error = {};

        if (response.data.status_code == 200) {
          this.fetchBankruptcyRecord(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        } else {
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "User",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "User",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Bankruptcy Record Check",
              "Something wrong, please try again!"
            );
            break;
        }
        this.loading = false;
      }
    },

    fnDeletePreviousNames: async function (i) {
      if (this.step2.previous_names.length > 0) {
        this.step2.previous_names.splice(i, 1);
        try {
          this.errors = {};

          await chkBankruptcyRecordService.addOrUpdate(
            this.projectId,
            this.step2
          );

          this.fetchBankruptcyRecord(this.projectId);
          messageService.fnToastSuccess("Previous name - Successfully added");
        } catch (error) {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;

            case 500:
              messageService.fnSweetAlertErrorToast(
                "Previous name",
                error.response.data.message
              );
              break;

            default:
              messageService.fnSweetAlertErrorToast(
                "Previous name",
                "Something wrong"
              );
              break;
          }
        }
      }
    },

    /**
     * Save the data if CVS
     */
    fnSave: async function () {
      this.loading = true;
      this.fnCheckValidation();

      if (this.arrErr.length > 0) {
        return;
      }
      try {
        const res = await chkBankruptcyRecordService.updateUserInfo(
          this.userId,
          this.step1
        );

        const response = await chkBankruptcyRecordService.addOrUpdate(
          this.projectId,
          this.step2
        );

        this.error = {};

        if (response.data.status_code == 200) {
          this.fetchBankruptcyRecord(this.projectId);
          this.fetchUserFromId(this.userId);

          messageService.fnToastSuccess("Saved successfully!");
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Bankruptcy Record Check",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Bankruptcy Record Check",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Bankruptcy Record Check",
              "Something wrong, please try again!"
            );
            break;
        }
        this.loading = false;
      }
    },
    /**
     * Check CC can update (submit)
     */
    can_cc_update() {
      let bol = false;
      let obj = this.getBankruptcyRecord;

      if (Object.keys(obj).length === 0) {
        bol = true;
      }

      if (obj.status < 2) {
        bol = true;
      }

      return bol;
    },

    /**
     * Check CVS can update (submit)
     */
    can_cvs_update() {
      let bol = false;
      let obj = this.getBankruptcyRecord;

      if (
        Object.keys(obj).length > 0 &&
        obj.status > 1 &&
        this.loginUserObj.role_id != 9
      ) {
        bol = true;
      }

      return bol;
    },

    fnCheckValidation() {
      let msg = [];

      if (
        (this.step2.previous_names_status == "Yes" &&
          this.step2.previous_names == null) ||
        (this.step2.previous_names_status == "Yes" &&
          this.step2.previous_names.length == 0)
      ) {
        msg.push("At least one previous name information is required");
      }
      this.arrErr = msg;
      return msg;
    },
    fnCallRefresh: function () {
      this.fetchUserFromId(this.userId);
      this.fetchBankruptcyRecord(this.projectId);
    },
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchBankruptcyRecord(this.projectId);
  },

  watch: {
    getUserFromId(newVal) {
      Object.assign(this.step1, newVal);
    },
    getBankruptcyRecord(newVal) {
      Object.assign(this.step2, newVal);
    },
  },
};
</script>
