<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h4>Employment History Check</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="step1.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="step1.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="step1.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
                <div class="invalid-feedback col-12" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.first_name == '' || step1.first_name == null"
                id="checkbox-1"
                name="checkbox-1"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_given_name"
              >
                Does not have a given name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_given_name"
              >
                {{ errors.have_a_given_name[0] }}
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.middle_name == '' || step1.middle_name == null"
                id="checkbox-2"
                name="checkbox-2"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_middle_name"
              >
                Does not have a middle name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_middle_name"
              >
                {{ errors.have_a_middle_name[0] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="date"
                  id="dateOfBirth"
                  autocomplete="off_dateOfBirth"
                  placeholder=" "
                  v-model="step1.date_of_birth"
                />
                <label class="floatingLabel" for="dateOfBirth"
                  >Date of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.date_of_birth"
                >
                  {{ errors.date_of_birth[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <p class="text-info">Employment History</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 text-right mb-3">
              <appModalEmploymentHistoryNew
                :projectId="projectId"
                :productId="7"
                :propsData="getEmploymentHistory"
              >
              </appModalEmploymentHistoryNew>
            </div>

            <div class="col-12 table-responsive">
              <table class="table table-bordered table-striped table-sm m-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name of organisation</th>
                    <th>
                      <p class="mb-0">Employer Payroll Phone Number</p>
                    </th>
                    <th>
                      <p class="mb-0">Employer address</p>
                    </th>
                    <th>
                      <p class="mb-0">Country</p>
                    </th>
                    <th style="width: 70px">Action</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    getEmploymentHistory &&
                    getEmploymentHistory.check_employment_history_trans
                  "
                >
                  <template
                    v-for="(
                      row, index
                    ) in getEmploymentHistory.check_employment_history_trans"
                  >
                    <tr :key="(row, index)">
                      <td
                        @click="toggle(index)"
                        :class="{ opened: opened.includes(index) }"
                      >
                        <span class="text-info pl-2"
                          ><i class="fas fa-outdent"></i
                        ></span>
                      </td>
                      <td>{{ row.name_of_organisation }}</td>
                      <td>{{ row.payroll_phone_number }}</td>
                      <td>{{ row.employer_address }}</td>
                      <td>{{ row.country_code }}</td>

                      <td>
                        <div>
                          <appModalEmploymentHistoryEdit
                            :projectId="projectId"
                            :productId="7"
                            :propsData="row"
                            @fnCallRefresh="fnCallRefresh"
                            class="d-inline"
                          >
                          </appModalEmploymentHistoryEdit>
                          <a
                            href="javascript: void(0)"
                            class="p-2 d-inline"
                            @click="fnDeleteEmploymentHistory(row.id)"
                            ><i class="far fa-times-circle text-info"></i
                          ></a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="opened.includes(index)">
                      <td colspan="10">
                        <div class="row mr-2 ml-2">
                          <div class="col-lg-6 col-md-6 col-12">
                            <dl class="row">
                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Employer HR/Payroll Email
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                {{ row.employer_payroll_email }}
                              </dd>
                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Manager Name
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                {{ row.manager_name }}
                              </dd>
                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Position Held From date
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                {{ row.date_from_position_held }}
                              </dd>
                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Please provide a reason for leaving
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                {{ row.reason_for_leaving }}
                              </dd>

                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Proof of employment
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                <div v-if="row.doc_urls">
                                  <a :href="`${row.doc_urls}`" target="_blank">
                                    <p class="text-black-60 fw-600 fs-900 mb-0">
                                      Doc
                                    </p></a
                                  >
                                </div>
                                <div v-else>
                                  <p class="text-black-60 fw-600 fs-900 mb-0">
                                    No document attached
                                  </p>
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <dl class="row">
                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Role Title
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                {{ row.role_title }}
                              </dd>
                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Is this your current employer
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                {{ row.is_current_employer }}
                              </dd>
                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Position Held to date
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                {{ row.date_to_position_held }}
                              </dd>

                              <dt class="col-sm-5">
                                <p class="text-black-60 fw-600 fs-900 mb-0">
                                  Can we contact this employer
                                </p>
                              </dt>
                              <dd class="col-sm-7">
                                {{ row.can_contact_employer }}
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-right">
              <router-link
                v-if="loginUserObj.role_id == 9"
                class="btn btn-secondary btn-sm mr-2"
                :to="{ name: 'dashboardCC' }"
                >Cancel</router-link
              >
              <button
                v-if="isSubmit == true"
                class="btn btn-info btn-sm"
                @click="fnUpdate()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Submit
              </button>
              <button
                v-if="isSave == true"
                class="btn btn-info btn-sm"
                @click="fnSave()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appCheckEmploymentHistoryIns></appCheckEmploymentHistoryIns>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as chkEmploymentHistoryService from "../../services/chkEmploymentHistory.service";

import * as messageService from "../../services/message.service";
import appCheckEmploymentHistoryIns from "../../components/checkEmploymentHistory/checkEmploymentHistoryIns.vue";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import appModalEmploymentHistoryNew from "./ModalEmploymentHistoryNew.vue";
import appModalEmploymentHistoryEdit from "./ModalEmploymentHistoryEdit.vue";

export default {
  name: "checkEmploymentHistory",
  props: ["mode", "projectId", "productId", "userId"],
  components: {
    appCheckEmploymentHistoryIns,
    appModalEmploymentHistoryNew,
    appModalEmploymentHistoryEdit,
  },
  data() {
    return {
      loginUserObj: {},
      errors: {},
      stateOfLicence: "",
      loading: false,
      opened: [],
      step1: {
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        country_of_birth_code: "Australia",
        email: "",
        mobile_no: "",
        have_a_given_name: "Yes",
        have_a_middle_name: "Yes",
        have_a_family_name: "Yes",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        country_code: "",
      },
      step2: {
        status: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getEmploymentHistory", "getUserFromId"]),
    isSubmit() {
      if (
        this.getEmploymentHistory &&
        this.getEmploymentHistory.status < 2 &&
        this.loginUserObj.role_id == 9
      ) {
        return true;
      }

      return false;
    },
    isSave() {
      if (
        this.getEmploymentHistory &&
        this.getEmploymentHistory.status > 1 &&
        this.loginUserObj.role_id != 9
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions(["fetchEmploymentHistory", "fetchUserFromId"]),

    /**
     * Submit the Data, it will use by the corporate customer
     */
    fnUpdate: async function () {
      this.loading = true;
      try {
        const res = await chkEmploymentHistoryService.updateUserInfo(
          this.userId,
          this.step1
        );
        this.step2.status = 2;
        const response = await chkEmploymentHistoryService.updateStatus(
          this.projectId,
          this.step2
        );

        this.error = {};

        if (response.data.status_code == 200) {
          this.fetchEmploymentHistory(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        } else {
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "User",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "User",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Employment History",
              "Something wrong, please try again!"
            );
            break;
        }
        this.loading = false;
      }
    },
    /**
     * Save the Data, it will use by the Admin and his staff
     */
    fnSave: async function () {
      this.loading = true;
      try {
        const res = await chkEmploymentHistoryService.updateUserInfo(
          this.userId,
          this.step1
        );
        const response = await chkEmploymentHistoryService.updateStatus(
          this.projectId,
          this.step2
        );

        this.error = {};

        if (response.data.status_code == 200) {
          this.fetchEmploymentHistory(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        } else {
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "User",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "User",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Employment History",
              "Something wrong, please try again!"
            );
            break;
        }
        this.loading = false;
      }
    },
    fnDeleteEmploymentHistory: async function (id) {
      try {
        this.errors = {};

        await chkEmploymentHistoryService.deleteSingle(id);

        this.fnCallRefresh();
        messageService.fnToastSuccess(
          "Employment History - Successfully deleted"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Employment History",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Employment History",
              "Something wrong"
            );
            break;
        }
      }
    },
    fnCallRefresh: function () {
      this.fetchUserFromId(this.userId);
      this.fetchEmploymentHistory(this.projectId);
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchEmploymentHistory(this.projectId);
  },

  watch: {
    getUserFromId(newVal) {
      Object.assign(this.step1, newVal);
    },
    getEmploymentHistory(newVal) {
      Object.assign(this.step2, newVal[0]);
    },
  },
};
</script>